import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'


const ThankYouPageContacto = () => {
  return (
    <div>
    
      
      <div className="contenedorform_contact">

        <br/>
        <br/>

        <div className="contenidofform_contact">


          <div className="form_contact">
        
            <h2 className='ThanksH2'>¡Gracias por contactarnos!</h2>
            <h3 className='ContactH3'>¡A la brevedad nos estaremos comunicando para poder asistirlo en lo que necesite!</h3>
            <Link to="/"> <Button size="md" className='homeButton'>Volver al Home</Button> </Link>

          </div>

        </div>
      </div> 

    </div>
  )
}

export default ThankYouPageContacto