import React from 'react';
import './Notfound.css';

const Notfound = () => {
  return (
    <div className='notfound py-3 ' >404 NOT FOUND</div>
  )
}

export default Notfound
