// Generic functions for firestore:
import { 
    getDocs, 
    getFirestore, 
    collection, 
    deleteDoc, 
    getDoc, 
    doc,
    query, 
    where,
    setDoc,
    addDoc
} from 'firebase/firestore';
import emailjs from '@emailjs/browser';
import { alertaError } from '../components/Alertas/alertas';
import app from './firebase';
import { parsearFechaLocal } from '../components/main/carrito/FormularioCompra';

const db = getFirestore(app);

export const getAllItems = async () => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "productos"), where("visible", "==", true)));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        })
        const docsMapped = docs.map(d => ({...d, oferta: (typeof(d.oferta)=="string" ? (d.oferta=="true" ? true : false) : d.oferta)}))
        return docsMapped;
    } catch (error){
        alertaError(error)
    }
};

export const getAllItemsAdmin = async () => {
    try{
        const querySnapshot = await getDocs(collection(db, "productos"));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        })
        const docsMapped = docs.map(d => ({...d, oferta: (typeof(d.oferta)=="string" ? (d.oferta=="true" ? true : false) : d.oferta)}))
        return docsMapped;
    } catch (error){
        alertaError(error)
    }
};

export const getSingleItem = async (id) => {
    try {
        const docRef = doc(db, "productos", id);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        
        return data;
    } catch (error) {
        alertaError(error);
    }
};

export const deleteItem = async (id) => {
    try {
        await deleteDoc(doc(db, 'productos', id));
    } catch (error) {
        alertaError(error);
    }
};

export const createItemWithId = async (data, id) => {
    try {
        await setDoc(doc(db, "productos", id.toString()), {
            ...data
        });
    } catch (error) {
        alertaError(error);
    }
};

function formatoprecio(n) {
    return n.toLocaleString("de-DE");
}

export const crearOrdenDeCompra = async (data) => {
    try {
        data.productos.map(p => {
            editItemStock(p, p.id)
        })
        const { id } = await addDoc(collection(db, "ordenes_de_compra"), {
            ...data
        });
        //  console.log("Firebase function funcionando")
        const templateParams = {
            id: id,
            nombre: data.nombre,
            apellido: data.apellido,
            telefono: data.telefono,
            user_email: data.mail,
            rol: data.rol,
            fecha: parsearFechaLocal(new Date()),
            productos: (`
            <table style="border-collapse: collapse; width: 100%;" border="1">
                <colgroup>
                    <col style="width: 16.6369%;">
                    <col style="width: 16.6369%;">
                    <col style="width: 16.6369%;">
                    <col style="width: 16.6369%;">
                    <col style="width: 16.6369%;">
                    <col style="width: 16.6369%;">
                </colgroup>
                <tbody>
                    <tr>
                        <td><span style="font-size: 14pt;"><b>ID</b></span></td>
                        <td><span style="font-size: 14pt;"><b>Producto</b></span></td>
                        <td><span style="font-size: 14pt;"><b>Oferta</b></span></td>
                        <td><span style="font-size: 14pt;"><b>P. Unit.</b></span></td>
                        <td><span style="font-size: 14pt;"><b>Cant.</b></span></td>
                        <td><span style="font-size: 14pt;"><b>Subtotal</b></span></td>
                    </tr>
                    ${data.productos.map(p => (
                        `<tr>
                            <td><span style="font-size: 14pt;">#${p.id}</span></td>
                            <td><span style="font-size: 14pt;">${p.titulo}</span></td>
                            <td><span style="font-size: 14pt;">${p.oferta===true ? "Si" : "No"}</span></td>
                            <td><span style="font-size: 14pt;">$${formatoprecio(p.precio)}</span></td>
                            <td><span style="font-size: 14pt;">x${p.cantidad}</span></td>
                            <td><span style="font-size: 14pt;">$${p.totalProducto}</span></td>
                        </tr>`
                    ))}
                </tbody>
            </table>
            <p><span style="font-size: 14pt;">Total sin descuento: $${formatoprecio(data.subtotal)}</span></p>
            <p><span style="font-size: 14pt;">Descuento:  ${data.descuento>0 ? `- $${formatoprecio(data.subtotal*(data.descuento/100))} (${data.descuento}%)` : `No aplica`}</span></p>
            <p><strong><span style="font-size: 14pt;">Total Final: $${formatoprecio(data.totalFinal)}</span></strong></p>
            `).replaceAll("</tr>,<tr>", "</tr>\n<tr>")
        };
        
        // console.log(templateParams)
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_COMPRA_TEMPLATE_ID, 
            templateParams, 
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then((result) => {
            // console.log(result.text);
        }, (error) => {
            // console.log(error.text);
        });
        return id
    } catch (error) {
        alertaError(error);
    }
};

export const editItem = async (data, id) => {
    try {
        await setDoc(doc(db, "productos", id.toString()), {
            ...data
        });
    } catch (error) {
        alertaError(error);
    }
};

export const editItemStock = async (data, id) => {
    try {
        await setDoc(doc(db, "productos", id.toString()), 
            ([data].map(({ cantidad, totalProducto, ...rest }) => ({...rest, stock: (Number(data.stock)-Number(data.cantidad))})))[0]
        );
    } catch (error) {
        alertaError(error);
    }
};

export const editItemStockAdmin = async (data, id) => {
    try {
        await setDoc(doc(db, "productos", id.toString()), 
            ([data].map(({ cantidad, ...rest }) => ({...rest, stock: (Number(data.stock)+Number(data.cantidad))})))[0]
        );
    } catch (error) {
        alertaError(error);
    }
};

/* Sección usuarios */
export const registrarUsuarioFS = (uid, email, rol, nombre, apellido, cuit) => {
    try {
        if(rol!=="admin"){
            const docuRef = doc(db, `usuarios/${uid}`);
            setDoc(docuRef, {email: email, rol: rol, nombre: nombre, apellido: apellido, cuit: cuit});
        }
    } catch (error) {
        alertaError(error)
    }
}

export const getRol = async (uid) => {
    const docuRef = doc(db, `usuarios/${uid}`);
    const docuCifrada = await getDoc(docuRef);
    const infoFinal = docuCifrada.data().rol;
    return infoFinal;
}

export const getAllUsers = async () => {
    try{
        const querySnapshot = await getDocs(collection(db, "usuarios"));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        })
        return docs;
    } catch (error){
        alertaError(error)
    }
};

export const getAllRoles = async () => {
    try{
        const querySnapshot = await getDocs(collection(db, "roles"));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data()});
        })
        return(Object.values(docs[0]));
    } catch (error){
        alertaError(error)
    }
}

export const editRoles = async (data) => {
    try {
        await setDoc(doc(db, "roles", "lista_roles"), {
            ...data
        });
    } catch (error) {
        alertaError(error);
    }
}

export const getAllCategorias = async () => {
    try{
        const querySnapshot = await getDocs(collection(db, "categorias"));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        })
        return([docs[0][0], docs[0][1], docs[0][2], docs[0][3], docs[0][4]]);
    } catch (error){
        alertaError(error)
    }
}

export const editCategorias = async (data) => {
    try {
        await setDoc(doc(db, "categorias", "lista_categorias"), {
            ...data
        });
    } catch (error) {
        alertaError(error);
    }
}

/* Ventas */
export const getAllSales = async () => {
    try{
        const querySnapshot = await getDocs(collection(db, "ordenes_de_compra"));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        })
        return docs;
    } catch (error){
        alertaError(error)
    }
};

export const editSaleStatus = async (data, id) => {
    try {
        await setDoc(doc(db, "ordenes_de_compra", id.toString()), {
            ...data
        });
    } catch (error) {
        alertaError(error);
    }
};
