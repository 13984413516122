import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllRoles, getAllSales } from '../../firebase/firebaseFunctions'

const initialState = {
    loading: false,
    error: '',
    modalEditOpen: false,
    modalCreateOpen: false,
    listaVentas: [],
    listaRoles: [],
}

// Generates pending, fulfilled and rejected action types
export const fetchVentas = createAsyncThunk('admin/fetchVentas', () => {
    return(getAllSales())
})

export const fetchRoles = createAsyncThunk('admin/fetchRoles', () => {
    return(getAllRoles())
})

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setEditOpen: (state, action) => {
            state.modalEditOpen = action.payload
        },
        setCreateOpen: (state, action) => {
            state.modalCreateOpen = action.payload
        },
        modSaleStatus: (state, action) => {
            state.listaVentas = state.listaVentas.map(i => i.id===action.payload.id ? (action.payload) : i)
        },
        setRoles: (state, action) => {
            state.listaRoles = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchVentas.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchVentas.fulfilled, (state, action) => {
            state.loading = false
            state.listaVentas = action.payload
            state.error = ''
        })
        builder.addCase(fetchVentas.rejected, (state, action) => {
            state.loading = false
            state.listaVentas = []
            state.error = action.error.message
        })
        builder.addCase(fetchRoles.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchRoles.fulfilled, (state, action) => {
            state.loading = false
            state.listaRoles = action.payload
            state.error = ''
        })
        builder.addCase(fetchRoles.rejected, (state, action) => {
            state.loading = false
            state.listaRoles = []
            state.error = action.error.message
        })
    }
})

export default adminSlice.reducer
export const { setEditOpen, setCreateOpen, modSaleStatus, setRoles } = adminSlice.actions