import React, { useState, useEffect } from 'react'
import ProductoCard from './ProductoCard.js'
import './ProductoGrid.css';
import { Row, Col, Alert } from 'react-bootstrap'
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filtrarListaXCateg, filtrarListaXPalabra, filtrarListaXSubcateg, filtrarXOferta } from '../../../redux/slices/productsSlice.js';

const ProductoGrid = () => {
  const { listaProductos, listaFiltrada, palabraBuscada } = useSelector((state) => state.products)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const { categoria, subcategoria, subCategId } = useParams()

  useEffect(() => {
      if(pathname.includes("productos/busqueda/")){
        dispatch(filtrarListaXPalabra())
      } else if(pathname==="/" || pathname==="/productos/ofertas"){
        dispatch(filtrarXOferta())
      } else if(subcategoria!==undefined && subCategId!==undefined){
        dispatch(filtrarListaXSubcateg({subcategoria: subcategoria, id: subCategId}))
      } else {
        dispatch(filtrarListaXCateg(categoria))
      }
      setLoading(false)

  }, [listaProductos, categoria, subcategoria, subCategId, palabraBuscada])

  return (
      <Row>
        {loading ?
          <>CARGANDO</>
          :
          <>
            {listaFiltrada && listaFiltrada.length>0 ?
              <>
                {pathname==="/productos/ofertas" ? <h2 className='offer text-capitalize'>Ofertas</h2> : <></>}
                {pathname==="/" ? 
                  <> 
                    {listaFiltrada.slice(0,12).map((p) => (
                      <Col key={p.id} className='mb-3' xs={12} sm={6} md={4} lg={3}> 
                        <ProductoCard producto={p}/> 
                      </Col>
                    ))}
                  </>
                  :
                  <>
                    {listaFiltrada.map((p) => (
                      <Col key={p.id} className='mb-3' xs={12} sm={6} md={4} lg={3}> 
                        <ProductoCard producto={p}/> 
                      </Col>
                    ))}
                  </>  
                }
              </>
            :
              <>
              {pathname=="/" ?
                <Alert key="primary" variant="primary" className='noProducts'>
                  Cargando ...
                </Alert>
              :
                <Alert key="primary" variant="danger" className='noProducts'>
                  No se encontraron productos
                </Alert>
              }
              </>
            }
          </>
        }


      </Row>

  )
}

export default ProductoGrid