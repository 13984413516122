import React from 'react'
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="grupo1">
          <div className="box1">
            <figure>
              <img src={`${process.env.PUBLIC_URL}/img/mainlogo.png`} alt="mainlogo" />
            </figure>
          </div>
          <div className="box2">
            <h2 className='titulo-principal-footer'>SOBRE NOSOTROS</h2>
            <p className='titulo-secundario-footer'> <b>Empresa de servicio de Computación y venta de Hardware.</b> </p>
            <p className='texto-footer'> Nuestro interés es poder crecer como empresa y otorgar un mejor servicio a los clientes cada día, ademas de asesorar y atender en materia electronica.</p>
          </div>
          <div className="box3">
            <h2>SEGUINOS</h2>
            <div className="redessociales">
              <a href="https://www.instagram.com/ventas_gecedata/" target="_blank" rel="noopener noreferrer"><button className="instagram" style={{backgroundColor: "#ff00ff"}}><FontAwesomeIcon icon={faInstagram} /></button></a>
              <a href="https://www.facebook.com/profile.php?id=100063533740518" target="_blank" rel="noopener noreferrer"><button className="facebook" style={{backgroundColor: "#f2d201"}}><FontAwesomeIcon icon={faFacebook} /></button></a>
              
            </div>
          </div>
        </div>
        <div className="grupo2">
            <small>Copyright &copy; 2022 <b>- GECEDATA</b> - Todos los derechos reservados. </small>
        </div>
      </footer>
    </div>
  )
}

export default Footer
