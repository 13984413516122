import React, { useEffect } from 'react';
import './Counter.css';
import { Button, Form, InputGroup} from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../redux/slices/productsSlice';

const Counter = (props) => {
    const url = useLocation()
    const dispatch = useDispatch()
    const { cantidad, setCantidad, stock, id, stockLimit, setStockLimit } = props
    const carrito = useSelector((state) => state.products.carrito)

    useEffect(() => {
        handleStock()
    }, [carrito])
    

    const handleMinus = () => {
        if(url.pathname ==="/carrito"){
            setCantidad(cantidad-1)
            dispatch(addToCart({id: props.id, cantidad: -1, precio:props.precio}))
        }
        else{
            setCantidad(cantidad-1)
        }
    }

    const handleAdd = () => {
        if(url.pathname ==="/carrito"){
            setCantidad(cantidad+1)
            dispatch(addToCart({id: props.id, cantidad: 1, precio:props.precio}))
        }
        else{
            setCantidad(cantidad+1)
        }
    }

    const handleStock = () => {
        if(url.pathname ==="/carrito"){
            setStockLimit(stock)
        }
        else{
            const isInCart = carrito.find(f => f.id===id)
            if(isInCart){
                setStockLimit(stock-isInCart.cantidad)
            } else{
                setStockLimit(stock)
            }
        }
    }

    return (
        <InputGroup className="my-3 counter">
            <Button 
                variant="secondary"
                id="button-addon1" 
                onClick={() => handleMinus()} 
                disabled={cantidad===1}
            >
                -
            </Button>
            <Form.Control
                aria-label="Counter"
                aria-describedby="basic-addon1"
                readOnly
                disabled
                value={cantidad}
                style={{textAlign: "center", backgroundColor: "white"}}
            />
            <Button 
                variant="secondary" 
                id="button-addon1" 
                onClick={() => handleAdd()}
                disabled={cantidad>=stockLimit}
            >
                +
            </Button>
        </InputGroup>
    )
}

export default Counter