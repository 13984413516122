import { configureStore } from '@reduxjs/toolkit'
import adminSlice from './slices/adminSlice'
import productsSlice from './slices/productsSlice'
import usersSlice from './slices/usersSlice'

const store = configureStore({
    reducer: {
        products: productsSlice,
        admin: adminSlice,
        users: usersSlice,
    },
    devTools: process.env.NODE_ENV !== 'production',
})

export default store