import React from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { login } from './funcionesAuth';

export function loginFallido(tipo) {
    if(tipo==="contraseña"){
        Swal.fire({
            icon: 'error',
            title: "Hubo un error. La contraseña es incorrecta.",
            showConfirmButton: false,
            timer: 2000
        })
    } else if(tipo==="usuario"){
        Swal.fire({
            icon: 'error',
            title: "Hubo un error. El email es incorrecto.",
            showConfirmButton: false,
            timer: 2000
        })
    }
}

export function loginExito() {
    Swal.fire({
        icon: 'success',
        title: "Has accedido con éxito",
        showConfirmButton: false,
        timer: 2000
    })
}


const DisplayingErrorMessagesSchema = Yup.object().shape({
    email: Yup.string()
        .email("Email inválido.")
        .required('Email requerido.'),
    contraseña: Yup.string()
        .min(6, "Debe tener como mínimo 6 caracteres.")
        .required("Contraseña requerida."),
});

const initialValues = {
    email: "",
    contraseña: "",
}

const LoginForm = ({ handleCloseLogin, setRegOpen, auth }) => {
    return (
        <Formik
        initialValues={initialValues}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={values => {
            login(signInWithEmailAndPassword, auth, values.email, values.contraseña)
        }}
        >
            {({values, errors, touched }) => (
                <Form className="modal-content animate">
                    <div className="imgcontainer">
                        <img src={`${process.env.PUBLIC_URL}/img/mainlogo.png`} alt="Gecedata" className="avatar"/>
                    </div>
                    <br/>
                    <Container>
                        <h2>Acceder</h2>
                        <h5>Complete los campos para acceder a su cuenta.</h5>
                        <hr/>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <Field name="email" className={(touched.email && errors.email) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.email && errors.email && <div className='text-danger mb-2'>{errors.email}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="contraseña">Contraseña:</label>
                            <Field type="password" name="contraseña" className={(touched.contraseña && errors.contraseña) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.contraseña && errors.contraseña && <div className='text-danger mb-2'>{errors.contraseña}</div>}
                        </div>
                        <button className="acceder1 mx-0 mb-4" style={{width: "100%"}} type="submit" onClick={handleCloseLogin}>Acceder</button>

                        <br/>
                        <div className="speach">
                            En caso de no contar con una cuenta podrás crearla aquí
                        </div>
                        <div className="botonRegestry" onClick={() => {handleCloseLogin(); setRegOpen(true)}}>
                            <span style={{cursor: "pointer"}} >Registrarme</span>
                        </div>
                        <div className="botonesModal d-flex justify-content-end">
                            {/* <Button variant="danger" onClick={handleCloseLogin}>Olvidó su contraseña</Button> */}
                            <Button variant="danger" onClick={handleCloseLogin}>Cerrar</Button>  
                        </div>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default LoginForm