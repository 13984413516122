import React, { useState, useEffect } from 'react'
import './ProductoDetail.css'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Notfound from '../Notfound'
import Counter from '../Counter'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart } from '../../../redux/slices/productsSlice' 
import Swal from 'sweetalert2'

const ProductoDetail = () => {
    const listaProductos = useSelector((state) => state.products.listaProductos)
    const { descuentoAplicado } = useSelector((state) => state.products)
    const dispatch = useDispatch()
    const { id } = useParams()

    const [cantidad, setCantidad] = useState(1)
    const [stockLimit, setStockLimit] = useState(0)
    const [producto, setProducto] = useState([])

    useEffect(() => {
      if(listaProductos!==undefined || listaProductos.length!==0){
        const productoSelected = (id!=undefined) ? listaProductos.find(f => f.id==id) : []
        setProducto(productoSelected)
        // console.log(productoSelected)
        if(productoSelected!==undefined){
          setStockLimit(productoSelected.stock ? productoSelected.stock : 0)
        }
      }
    }, [id, listaProductos])

    function productoAñadido(nombre, cantidad) {
      Swal.fire({
        icon: 'success',
        title: `Se ha${cantidad>1 ? "n" : ""} añadido ${cantidad} ${nombre} al carrito`,
        showConfirmButton: false,
        timer: 2000
      })
    }

    const handleAddToCart = () => {
      dispatch(addToCart({...producto, cantidad: cantidad}))
      setCantidad(1)
      productoAñadido(producto.titulo, cantidad)
    }

    function formatoprecio(n) {
      return (n ? n.toLocaleString("de-DE") : '')
    }

    return (
        <>
            <Container>
                {producto && producto ?
                    <Row>
                        <Col xs={12} lg={3}>
                            {producto.imagen && producto.imagen!=="" ?
                              <img className="imgProduct" src={producto.imagen} alt={producto.titulo}/>
                              :
                              <img className="imgProduct" src={`${process.env.PUBLIC_URL}/img/producto_sin_imagen.png`} alt={producto.titulo}/>
                            }
                        </Col>
                        <Col xs={12} lg={9}>
                          <Row className="rowDetails1">
                            <Col className="fuente2" md={7}>
                              <p className='fuente3'>{producto.titulo}</p>
                              {(producto.descripcion !== "" && producto.descripcion !== null && producto.descripcion !== undefined ) ? 
                              <p className='fuente5 fs-6'>{producto.descripcion}</p>
                              :<p className='fuente5 fs-6 text-center'>Este producto no cuenta con una descripción.</p>
                              } 
                            </Col>
                            <Col className="fuente6" md={5}>
                              <p className='fuente4'>${descuentoAplicado ? formatoprecio(producto.precio*((100-descuentoAplicado)/100)) : formatoprecio(producto.precio)}</p>
                              <div className="productoBeneficios">
                                  {/* <div class="iconoContainer">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="29.546" viewBox="0 0 25 29.546">
                                        <path id="Trazado_2147" data-name="Trazado 2147" d="M18,26.855v3.87a19.783,19.783,0,0,0,1.349,8.021,13.184,13.184,0,0,0,8.2,7.148,13.185,13.185,0,0,0,8.2-7.148,19.783,19.783,0,0,0,1.349-8.021v-3.87a22.39,22.39,0,0,1-9.553-3.6A22.392,22.392,0,0,1,18,26.855m14.952,4.427-7.161,7.161-3.5-3.5,1.13-1.13,2.374,2.374,6.031-6.031Z" transform="translate(-15.053 -19.448)" fill="#01AEF2"></path>
                                        <path id="Trazado_2148" data-name="Trazado 2148" d="M12.5,0S8.548,4.371,0,4.72v6.556c0,3.934.087,14.51,12.5,18.269C24.913,25.787,25,15.21,25,11.276V4.72C16.452,4.371,12.5,0,12.5,0M23.526,11.276a21.1,21.1,0,0,1-1.472,8.611c-1.692,3.878-4.9,6.606-9.554,8.114-4.649-1.508-7.862-4.236-9.554-8.114a21.1,21.1,0,0,1-1.472-8.611V6.1A20.68,20.68,0,0,0,12.5,1.976,20.68,20.68,0,0,0,23.526,6.1Z" fill="#01AEF2"></path>
                                      </svg>
                                      <p className="special_p">Producto <b>con garantia</b></p>
                                  </div>
                                  {/* <div className="iconoContainer">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24.971" viewBox="0 0 22 24.971">
                                        <path id="Trazado_2153" data-name="Trazado 2153" d="M19.475,10.8V8.475a8.475,8.475,0,1,0-16.95,0V10.8H0V24.971H22V10.8Zm-5.5,0H8.024V8.475a2.976,2.976,0,1,1,5.952,0ZM3.944,12.3H17.9V23.471H3.944ZM11,1.5a6.983,6.983,0,0,1,6.975,6.975v.168h-2.5V8.475a4.476,4.476,0,1,0-8.951,0v.168h-2.5V8.475A6.983,6.983,0,0,1,11,1.5" fill="#01AEF2"></path>
                                        <path id="Trazado_2154" data-name="Trazado 2154" d="M57.923,91.595a2.021,2.021,0,1,0-2.861,1.836v2.058H56.7V93.45a2.021,2.021,0,0,0,1.22-1.855" transform="translate(-44.902 -74.647)" fill="#01AEF2"></path>
                                      </svg>
                                      <p className="special_p"><b>Transaccion segura</b></p>
                                  </div> */}
                                  <div className="iconoContainer">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28.398" height="22.386" viewBox="0 0 28.398 22.386">
                                        <path id="Trazado_2150" data-name="Trazado 2150" d="M103.07,80.253H96.921v5.619h6.708V80.253h-.559Zm-.849,4.21H98.329v-2.8h3.892Z" transform="translate(-81.756 -67.696)" fill="#01AEF2"></path>
                                        <path id="Trazado_2151" data-name="Trazado 2151" d="M28.045,7.718,22.934,0H5.464L0,8.252a4.133,4.133,0,0,0,3.774,2.884v11.25H24.624V11.136A4.133,4.133,0,0,0,28.4,8.252ZM14.9,1.408h3.575l1.458,4.9H14.9Zm5.342,6.31a2.718,2.718,0,0,1-5.25,0ZM6.22,1.408H8.377L6.919,6.31H2.975ZM1.6,8.392l.446-.674H6.56A2.717,2.717,0,0,1,1.6,8.392M12.469,20.977H8.036V13.965h4.433Zm10.747,0H13.877V12.557H6.628v8.421H5.182V10.947a4.143,4.143,0,0,0,2.174-1.63,4.166,4.166,0,0,0,6.843,0,4.166,4.166,0,0,0,6.843,0,4.144,4.144,0,0,0,2.174,1.63Z" fill="#01AEF2"></path>
                                      </svg>
                                      <div className="textoContainer">
                                        <p className="special_p"><b>Retira gratis </b>en nuestro local</p>
                                        <a href="https://www.google.com/maps/place/Cnel.+Pablo+Zufriategui+794,+B1714GDJ+Ituzaing%C3%B3,+Provincia+de+Buenos+Aires/@-34.6584941,-58.6682217,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcbf5f82539421:0xe7d6e212b064408d!8m2!3d-34.6584985!4d-58.666033?hl=es" target="_blank" rel="nofollow">Cnel. Pablo Zufriategui 794, Ituzaingó Norte</a>
                                      </div>
                                  </div>
                                  <div className='iconoContainer'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.398" height="22.386" viewBox="0 0 640 512">
                                      <path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32z" fill="#01AEF2"/>
                                    </svg>
                                      <div className='textoContainer'>
                                      <p className="special_p">Contamos con <b>Motomensajeria</b></p>
                                      </div>

                                  </div>
                              </div>
                              <div className="counterButton">
                                {cantidad<=stockLimit ?
                                  <>
                                    <Counter
                                      cantidad={cantidad} 
                                      setCantidad={setCantidad}
                                      stock={producto.stock}
                                      precio={producto.precio}
                                      id={producto.id}
                                      stockLimit={stockLimit}
                                      setStockLimit={setStockLimit}
                                    />
                                    <Button 
                                      className="addCart" 
                                      style={{color: "black", backgroundColor: "#01AEF2", borderColor: "#01AEF2"}}
                                      onClick={() => handleAddToCart()}
                                    >
                                      Agregar al Carrito
                                    </Button>
                                  </>
                                : <>Sin Stock</>
                                }
                              </div>
                            </Col>
                          </Row> 
                        </Col>
                    </Row>
                    :
                    <Notfound />
                  }
            </Container>
        </>
    )
}

export default ProductoDetail