import React, { useState } from 'react'
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBars, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import MenuResponsive from './MenuResponsive';
import BotonCarrito from './BotonCarrito';
import { useDispatch, useSelector } from 'react-redux';
import { setPalabraBuscada } from '../../redux/slices/productsSlice';
import appFirebase from '../../firebase/firebase';
import { getAuth } from 'firebase/auth';
import { cerrarSesion } from './authForms/funcionesAuth';
import RegisterForm from './authForms/RegisterForm';
import LoginForm from './authForms/LoginForm';

const auth = getAuth(appFirebase) 

const Navbar = () => {

  // Estados del Modal
  const [loginOpen, setLoginOpen] = useState(false)
  const [regOpen, setRegOpen] = useState(false)
  const [busqueda, setBusqueda] = useState("")

  const dispatch = useDispatch()
  const { usuario } = useSelector((state) => state.users)

  const handleCloseRegister = () => {
    setRegOpen(false)
  }
  const handleCloseLogin = () => {
    setLoginOpen(false)
  }

  // Estados del Menu Responsive
  const [menuOpen, setMenuOpen] = useState(false)
  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  return (
  <div className='header_nav'>
    <div>
      <header>
        <div className="header_principal">
          <div className="contenedor-logo">
          <Link to="/"> <img src={`${process.env.PUBLIC_URL}/img/mainlogo.png`} alt="mainlogo"/></Link>
          </div>

          <div className="contenedor-buscador-header">
            <InputGroup className="m-3">
              <Form.Control
                aria-label="Buscar"
                placeholder="Buscar.."
                value={busqueda}
                onChange={e => {setBusqueda(e.target.value)}}
              />
              <Link to={`/productos/busqueda/${busqueda}`}>
                <Button className='searchButton' variant="outline-secondary" onClick={() => dispatch(setPalabraBuscada(busqueda))}>
                  <FontAwesomeIcon icon={faMagnifyingGlass}/>
                </Button>
              </Link>
            </InputGroup>
          </div>          

          <div className="headerButtons">
            {usuario ? <button className='salir' onClick={() => cerrarSesion(auth)}>Cerrar Sesión</button> :
              <>
                <button className='persona' type="button" style={{width:"auto"}} ><FontAwesomeIcon icon={faUser}/></button>
                <button className='registrarse' type="button" style={{width:"auto"}} onClick={() => setRegOpen(true)}>
                  Registrarse
                </button>
                <button className='acceder' type="button" style={{width:"auto"}} onClick={() => setLoginOpen(true)}>
                  Acceder
                </button>
              </>
            }
            <BotonCarrito />
            {(usuario && usuario.rol==="admin") ? 
              <Link to="admin"><Button>Admin</Button></Link>
            : 
              <></>
            }
          </div>
        </div>

        <div className="header_responsiv">
          <div className="contenedor-logo">
          <Link to="/"> <img src={`${process.env.PUBLIC_URL}/img/mainlogo.png`} alt="mainlogo"/></Link>
          </div>

          <div className="contenedor-buscador-header">
            <InputGroup className="m-3">
              <Form.Control
                aria-label="Buscar"
                placeholder="Buscar.."
                value={busqueda}
                onChange={e => {setBusqueda(e.target.value)}}
              />
              <Link to={`/productos/busqueda/${busqueda}`}>
                <Button variant="outline-secondary" onClick={() => dispatch(setPalabraBuscada(busqueda))}>
                  <FontAwesomeIcon icon={faMagnifyingGlass}/>
                </Button>
              </Link>
            </InputGroup>
          </div>          
          <div className="headerButtons">
          {usuario ? <button className='salir' onClick={() => cerrarSesion(auth)}>Cerrar Sesión</button> :
            <>
              <button className='persona' style={{width:"auto"}} onClick={() => setLoginOpen(true)}><FontAwesomeIcon icon={faUser}/></button>
              <button className='registrarse' style={{width:"auto"}} onClick={() => setRegOpen(true)}>Registrarse</button>
              <button className='acceder' style={{width:"auto"}} onClick={() => setLoginOpen(true)}>Acceder</button>
            </>}
            <BotonCarrito />
            {(usuario && usuario.rol==="admin") ? 
              <Link to="admin"><Button>Admin</Button></Link>
            : 
              <></>
            }
          </div>
        </div>
        </header>

          <div className="contenedor_Nav_principal">
            <nav className="nav_principal">
              <Link to="/productos/hardware"> Hardware </Link>
              <Link to="/productos/audio"> Audio </Link>
              <Link to="/productos/almacenamiento"> Almacenamiento </Link>
              <Link to="/productos/insumos"> Insumos </Link>
              <Link to="/productos/impresion"> Impresion </Link>
              <Link to="/contacto"> Contacto</Link>
            </nav> 
            <div className="nav_responsiv">
              <div className="contenedor-buscador-nav">
                <InputGroup className="m-3">
                  <Form.Control
                    aria-label="Buscar"
                    placeholder="Buscar.."
                    value={busqueda}
                    onChange={e => {setBusqueda(e.target.value)}}
                  />
                  <Link to={`/productos/busqueda/${busqueda}`}>
                    <Button className='searchButton' variant="outline-secondary" onClick={() => dispatch(setPalabraBuscada(busqueda))}>
                      <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    </Button>
                  </Link>
                </InputGroup>
              </div>
            </div>         
          </div>
  </div>
          <div>
            <button className="Menu" onClick={() => setMenuOpen(true)}><FontAwesomeIcon icon={faBars}/></button>
          </div>                          

        <Modal show={regOpen} onHide={handleCloseRegister}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <RegisterForm 
              handleCloseRegister={handleCloseRegister}
              auth={auth}
            />
          </Modal.Body>
        </Modal>
        <Modal show={loginOpen} onHide={handleCloseLogin}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <LoginForm 
              handleCloseLogin={handleCloseLogin}
              setRegOpen={setRegOpen}
              auth={auth}
            />
          </Modal.Body>
        </Modal>

        <MenuResponsive menuOpen={menuOpen} handleCloseMenu={handleCloseMenu}/>
    </div>
  )
}

export default Navbar
