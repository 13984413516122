import React from 'react'
import './ProductoCard.css'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom' ;
import { useSelector } from 'react-redux';

function ProductoCard(props) {
    const { descuentoAplicado } = useSelector((state) => state.products)
	
	function formatoprecio(n) {
		return n.toLocaleString("de-DE");
	}

	return (
			<Link to={`/producto/${props.producto.id}`}>
				<Card className="card">
					{props.producto.imagen && props.producto.imagen!=="" ?
						<Card.Img variant="top" src={props.producto.imagen} />
						:
						<Card.Img variant="top" src={`${process.env.PUBLIC_URL}/img/producto_sin_imagen.png`} />
					}
					<Card.Body>
						<Card.Title className="title">{props.producto.titulo}</Card.Title>
						<Card.Text className="price">${descuentoAplicado ? formatoprecio(props.producto.precio*((100-descuentoAplicado)/100)) : formatoprecio(props.producto.precio)}</Card.Text>
					</Card.Body>
					<Card.Footer>
						<button> Comprar Producto </button>
					</Card.Footer>
				</Card>
			</Link>
	);
}

export default ProductoCard