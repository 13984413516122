import Swal from "sweetalert2"

export const alertaError = (texto) => {
    Swal.fire({
        icon: 'error',
        title: "Error",
        text: texto,
        showConfirmButton: false,
        timer: 2500
    })
}

export const alertaExito = (texto) => {
    Swal.fire({
        icon: 'success',
        text: texto,
        showConfirmButton: false,
        timer: 2000
    })
}

export const alertaInfo = (texto) => {
    Swal.fire({
        icon: 'info',
        text: texto,
        showConfirmButton: true,
        timer: 2000
    })
}