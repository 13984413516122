import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const BotonCarrito = () => {
    const carritoCant = useSelector((state) => state.products.carritoCant)

    return (
        <Link to="/carrito">
            <button className="carrito" type="button">
            <FontAwesomeIcon icon={faCartShopping}/>
            {carritoCant>0 ?
                <Badge bg="primary" className='badge_cart'>{carritoCant}</Badge>
            : <></>
            }
            </button>
        </Link>
    )
}

export default BotonCarrito