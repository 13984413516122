import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Table, Modal } from 'react-bootstrap'
import { useNavigate, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import RolesForm from './RolesForm';
import { fetchRoles } from '../../redux/slices/adminSlice';

const ControlRoles = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { usuario } = useSelector((state) => state.users)
    const { listaRoles } = useSelector((state) => state.admin)

    const [modalEditContent, setModalEditContent] = useState([])
    const [modalEditOpen, setModalEditOpen] = useState(false)

    const handleEditClose = () => {
        setModalEditOpen(false)
        setModalEditContent([])
    }

    const handleEditOpen = (r) => {
        setModalEditOpen(true)
        setModalEditContent(r)
    }

    const shouldFetch = useRef(true)
    useEffect(() => {
        async function startFetching() {
            if (shouldFetch.current && listaRoles.length===0) {
                shouldFetch.current = false
                dispatch(fetchRoles())
            }
        }
        if (listaRoles.length===0) {
            startFetching();
        }
    }, []);

    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <>
            <Button 
                variant="danger" 
                className='ms-3 mt-3' 
                style={{position: "absolute"}}
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Container>
                <p className='fs-4 mt-5'>Gestión de Roles y Descuentos</p>
                <p className='fs-6 border border-0 my-3'>En este sector se pueden editar los descuentos de los roles existentes.</p>
            </Container>
            <Container>
                <Table striped bordered className='shadow-lg'>
                    <thead>
                        <tr>
                            <th>Rol</th>
                            <th>Descuento</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {console.log(listaRoles)} */}
                        {listaRoles && listaRoles.length!==0 && listaRoles.map((r) => (
                            <tr key={r.nombre}>
                                <td>{r.nombre}</td>
                                <td className='fw-bold'>{r.descuento>0 ? `${r.descuento}%` : "No tiene"}</td>
                                <td>
                                    <Button onClick={() => handleEditOpen(r)}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

            <Modal size="lg" show={modalEditOpen} onHide={handleEditClose}>
                <Modal.Header closeButton className='fs-5'>
                    Modificar descuento del rol: "{modalEditContent.nombre}"
                </Modal.Header>
                <Modal.Body>
                    <RolesForm rolAEditar={modalEditContent} handleEditClose={handleEditClose}  />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ControlRoles