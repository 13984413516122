import React from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { editCategorias } from '../../firebase/firebaseFunctions'
import { useDispatch, useSelector } from 'react-redux';
import { setCategorias } from '../../redux/slices/productsSlice';
import { alertaError } from '../Alertas/alertas';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    nombre: Yup.string()
        .max(16, 'Debe ser tener 16 o menos caracteres')
        .required('Requerido'),
    visible: Yup.boolean()
        .required('Requerido')
});

const CategoriasForm = (props) => {
    const dispatch = useDispatch();
    const { handleModalClose } = props
    const categoriaPadre = props.categoriaPadre
    const subcategoriaPadre = props.subcategoriaPadre
    const { categorias } = useSelector((state) => state.products)
    const { usuario } = useSelector((state) => state.users)

    const handleSubmitCreate = (values) => {
        try {
            if(!subcategoriaPadre){
                const categoriasModif = categorias.map(c => c.nombre===categoriaPadre ? 
                    ({...c, subfiltros: [...c.subfiltros, {...values, lista:[]}]}) 
                : (c))
                // console.log(categoriasModif)
                editCategorias(categoriasModif)
                dispatch(setCategorias(categoriasModif))
            } else{
                const categoriasModif = categorias.map(c => c.nombre===categoriaPadre ? 
                    ({...c, subfiltros: c.subfiltros.map(subc => subc.nombre===subcategoriaPadre ? 
                        ({...subc, lista: [...subc.lista, {...values}]}) 
                    : (subc))}) 
                : (c))
                // console.log(categoriasModif)
                editCategorias(categoriasModif)
                dispatch(setCategorias(categoriasModif))
            }
            handleModalClose()
        } catch (error) {
            alertaError(error)
            handleModalClose()
        }
    }

    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <Container>
            <Formik
            initialValues={
                {
                    nombre: "",
                    visible: true
                }
            }
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={values => {
                handleSubmitCreate(values)
            }}
            >
                {({values, errors, touched }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre:</label>
                            <Field name="nombre" className={(touched.nombre && errors.nombre) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.nombre && errors.nombre && <div className='text-danger mb-2'>{errors.nombre}</div>}
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="visible">Visible:</label>{' '}
                            {values.visible ? "Visible" : "Oculto"}{' '}
                            <Field className="form-check-input" type="checkbox" name="visible" />
                            
                            {touched.visible && <div className='text-danger mb-2'>{errors.visible}</div>}
                        </div>
                        
                        <Button type="submit">Crear</Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CategoriasForm