import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import './ThankYouPage.css';
import { useSelector } from 'react-redux';

const ThankYouPage = () => {
  const { ordenCompra } = useSelector((state) => state.products)

  return (
    <div>
      
      <div className="contenedorform_contact">

        <br/>
        <br/>

        <div className="contenidofform_contact">

          <div className="form_contact">
        
            <h2 className='ThanksH2'>¡Gracias por tu compra!</h2>
            <h3>Se creo la orden de compra con código: #{ordenCompra.nroOrden}</h3>
            <h3 className='ContactH3'>¡Nos pondremos en contacto con vos enseguida para coordinar y concretar la venta!</h3>
            <Link to="/"> <Button size="md" className='homeButton'>Volver al Home</Button> </Link>   

          </div>

        </div>
      </div> 

    </div>
  )
}

export default ThankYouPage