import React, { useEffect, useRef, useState } from 'react'
import './ControlVentas.css';
import { Button, Container, InputGroup, Form, Table, Modal } from 'react-bootstrap'
import { useNavigate, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVentas } from '../../redux/slices/adminSlice';
import VentaForm from './VentaForm';

export const parsearFecha = (fecha) => {
    const date = (fecha).toDate()
    const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
    const [hour, minutes] = [date.getHours(), date.getMinutes()];
    return(`${day}/${month+1}/${year} ${hour}:${minutes<=9 ? "0" : ""}${minutes}`)
}

const ControlVentas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { usuario } = useSelector((state) => state.users)
    const { listaVentas } = useSelector((state) => state.admin)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState([])
    const [modalEditContent, setModalEditContent] = useState([])
    const [modalEditOpen, setModalEditOpen] = useState(false)

    const [palabraBusc, setPalabraBusc] = useState("")
    const [listaFiltrada, setListaFiltrada] = useState(listaVentas ? listaVentas : [])

    const formatoprecio = (n) => (n ? n.toLocaleString("de-DE") : '')

    const handleClose = () => {
        setModalOpen(false)
        setModalContent([])
    }
    
    const handleOpen = (p) => {
        setModalContent(p)
        setModalOpen(true)
    }

    const handleEditClose = () => {
        setModalEditOpen(false)
        setModalEditContent([])
    }

    const handleEditOpen = (p) => {
        setModalEditOpen(true)
        setModalEditContent(p)
    }

    const shouldFetch = useRef(true)
    useEffect(() => {
        async function startFetching() {
            if (shouldFetch.current && listaVentas.length===0) {
                shouldFetch.current = false
                dispatch(fetchVentas())
            }
        }
        if (listaVentas.length===0) {
            startFetching();
        }
    }, []);

    useEffect(() => {
    const lista = listaVentas.filter(str => str.id.includes(palabraBusc) || str.mail.includes(palabraBusc) || str.telefono.includes(palabraBusc) || str.nombre.includes(palabraBusc) || str.apellido.includes(palabraBusc) || str.rol.includes(palabraBusc))
        if(lista!==listaFiltrada){
            setListaFiltrada(lista)
            if(palabraBusc===""){
                setListaFiltrada(listaVentas)
            }
        }
    }, [palabraBusc, listaVentas])

    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <>
            <Button 
                variant="danger" 
                className='ms-3 mt-3' 
                style={{position: "absolute"}}
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Container>
                <p className='fs-4 ms-5'>Gestión de Ventas</p>
                <p className='fs-6 border border-0 my-3'>
                    En este sector se pueden visualizar las ventas realizadas asi como también cambiar sus estados (Pendiente, Pago, Cancelado).
                    <br/>
                    Si se desea actualizar las órdenes de compra se debe recargar la página.
                </p>
            </Container>
            <Container>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Buscador"
                    aria-label="Buscador"
                    onChange={(e) => setPalabraBusc(e.target.value)}
                    />
                </InputGroup>
            </Container>
            <Container>
                <Table striped bordered className='shadow-lg'>
                    <thead>
                        <tr>
                            <th>Estado</th>
                            <th>Fecha</th>
                            <th>Orden</th>
                            <th>Email</th>
                            <th>Importe</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaFiltrada.length>0 && listaFiltrada.map((p) => (
                            <tr key={p.id} className={p.status==="pendiente" ? "colorPendiente" : p.status==="pago" ? "colorPago" : "colorCancelado"}>
                                <td className='text-capitalize'>{p.status}</td>
                                <td>{p.fecha && p.fecha}</td>
                                <td>{p.id}</td>
                                <td>{p.mail}</td>
                                <td>${formatoprecio(p.totalFinal*((100-p.descuento)/100))}</td>
                                <td>
                                    <Button onClick={() => handleOpen(p)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>{' '}
                                    <Button onClick={() => handleEditOpen(p)}>
                                        <FontAwesomeIcon icon={faCashRegister} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

            <Modal size="lg" show={modalOpen} onHide={handleClose} className='text-center '>
                <Modal.Header closeButton className='fs-5 ContenedorModal'>
                    Detalle Orden de Compra #{modalContent.id}
                </Modal.Header>
                
                <Modal.Body className='ContenedorModal' >
                    <div className='row m-5'>        
                        
                        <div className='col'>
                            <div className='fw-bold justify-content-start p-2 TextoControlProductos'>Estado: </div> 
                            <p className='text-capitalize'> {modalContent.status} </p>
                        </div>
                        
                        <div className='col'>
                            <div className='fw-bold justify-content-center p-2 TextoControlProductos'>Fecha: </div>
                            <p> {modalContent.fecha && modalContent.fecha} </p>
                        </div>
                        
                        <div className='col'>
                            <div className='fw-bold justify-content-end p-2 TextoControlProductos'>Subtotal (s/descuento): </div>
                            <p> ${formatoprecio(modalContent.totalFinal)} </p>
                        </div>
                    
                    </div>
                    <div className='row m-5'>

                        <div className='col'>
                            <div className='fw-bold justify-content-start p-2 TextoControlProductos'>Nombre y Apellido: </div> 
                            <p> {`${modalContent.nombre} ${modalContent.apellido}`} </p>                       
                        </div>
                        <div className='col'>
                            <div className='fw-bold justify-content-center p-2 TextoControlProductos'>Rol: </div>
                            <p className='text-capitalize'> {modalContent.rol} </p>
                        </div>
                        <div className='col'>
                            <div className='fw-bold justify-content-end p-2 TextoControlProductos'>Teléfono: </div>
                            <p> {modalContent.telefono} </p>
                        </div>   

                    </div>   
                    <div className='row m-5'>

                        <div className='col'>
                            <div className='fw-bold DescripcionControl justify-content-start p-2 TextoControlProductos'>Detalle: </div>
                            <Table striped bordered className='shadow-lg'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Producto</th>
                                        <th>Cód de Barra</th>
                                        <th>Cant.</th>
                                        <th>P. Unit.</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalContent.productos && modalContent.productos.length>0 && modalContent.productos.map((p) => (
                                        <tr key={p.id}>
                                            <td>{p.id}</td>
                                            <td>{p.titulo}</td>
                                            <td>{(modalContent.codigo_barra && modalContent.codigo_barra!=="") ? modalContent.codigo_barra : "No tiene"}</td>
                                            <td>x{p.cantidad}</td>
                                            <td>${formatoprecio(p.precio)}</td>
                                            <td>${formatoprecio(p.totalProducto)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                    </div>    
                    <div className='row m-2'>
                        <div className='col'>
                            <div className='fw-bold justify-content-start p-2 TextoControlProductos'>Subtotal: </div> 
                            <p> ${formatoprecio(modalContent.totalFinal)} </p>
                        </div>
                    </div>
                    <div className='row m-2'>
                        <div className='col'>
                            <div className='fw-bold justify-content-start p-2 TextoControlProductos'>Descuento: </div> 
                            <p> {modalContent.descuento && modalContent.descuento>0 ? <>- ${formatoprecio((modalContent.totalFinal*modalContent.descuento)/100)} {' '} <span className='text-success fw-bold ms-3'>({modalContent.descuento}%)</span></> : <>No Aplica</>}</p>
                        </div>
                    </div>
                    <div className='row m-2'>
                        <div className='col'>
                            <div className='fw-bold justify-content-start p-2 TextoControlProductos'>Total a pagar: </div> 
                            <p className='fs-4 fw-bold'> ${formatoprecio(modalContent.totalFinal*((100-modalContent.descuento)/100))} </p>
                        </div>
                    </div>
                        
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={modalEditOpen} onHide={handleEditClose}>
                <Modal.Header closeButton className='fs-5'>
                    Modificar estado de la venta #{modalEditContent.id}
                </Modal.Header>
                <Modal.Body>
                    <VentaForm modalEditContent={modalEditContent} handleEditClose={handleEditClose}  />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ControlVentas