import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, InputGroup, Form, Table } from 'react-bootstrap'
import { useNavigate, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsuarios } from '../../redux/slices/usersSlice';

const ControlUsuarios = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { usuario, listaUsuarios } = useSelector((state) => state.users)

    const [palabraBusc, setPalabraBusc] = useState("")
    const [listaFiltrada, setListaFiltrada] = useState(listaUsuarios ? listaUsuarios : [])

    const shouldFetch = useRef(true)
    useEffect(() => {
        async function startFetching() {
            if (shouldFetch.current && listaUsuarios.length===0) {
                shouldFetch.current = false
                dispatch(fetchUsuarios())
            }
        }
        if (listaUsuarios.length===0) {
            startFetching();
        }
    }, []);

    useEffect(() => {
        const lista = listaUsuarios.filter(str => str.rol.includes(palabraBusc) || str.nombre.includes(palabraBusc) || str.apellido.includes(palabraBusc) || str.email.includes(palabraBusc) || (str.cuit && str.cuit.includes(palabraBusc)))
        if(lista!==listaFiltrada){
            setListaFiltrada(lista)
            if(palabraBusc===""){
                setListaFiltrada(listaUsuarios)
            }
        }
    }, [palabraBusc, listaUsuarios])

    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <>
            <Button 
                variant="danger" 
                className='ms-3 mt-3' 
                style={{position: "absolute"}}
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Container>
                <p className='fs-4 ms-5'>Gestión de Usuarios</p>
                <p className='fs-6 border border-0 my-3'>En este sector se pueden visualizar los usuarios y sus roles</p>
            </Container>
            <Container>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Buscador"
                    aria-label="Buscador"
                    onChange={(e) => setPalabraBusc(e.target.value)}
                    />
                </InputGroup>
            </Container>
            <Container>
                <Table striped bordered className='shadow-lg'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Email</th>
                            <th>CUIT</th>
                            <th>Rol</th>
                            {/* <th>Acciones</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {listaFiltrada.length>0 && listaFiltrada.map((p) => (
                            <tr key={p.id}>
                                <td>{p.nombre}</td>
                                <td>{p.apellido}</td>
                                <td>{p.email}</td>
                                <td>{p.cuit ? p.cuit : "No encontrado"}</td>
                                <td className='text-capitalize'>{p.rol}</td>
                                {/* <td>
                                    <Button>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </>
    )
}

export default ControlUsuarios