import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './normalize.css';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import Navbar from './components/navbar/Navbar';
import Main from './components/main/Main';
import Footer from './components/footer/Footer';
import ProductoContainer from './components/main/producto/ProductoContainer';
import Contacto from './components/main/Contacto';
import ProductoDetail from './components/main/producto/ProductoDetail';
import FormularioCompra from './components/main/carrito/FormularioCompra';
import Carrito from './components/main/carrito/Carrito';
import ProductoForm from './components/admin/ProductoForm';
import AdminDashboard from './components/admin/AdminDashboard';
import ControlProductos from './components/admin/ControlProductos';
import ControlVentas from './components/admin/ControlVentas';
import ControlUsuarios from './components/admin/ControlUsuarios';
import ControlRoles from './components/admin/ControlRoles';
import ControlCategorias from './components/admin/ControlCategorias';
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategorias, fetchProducts, setDescuentoAplicado } from './redux/slices/productsSlice'
import { setUsuario } from './redux/slices/usersSlice';
import ThankYouPage from './components/main/carrito/ThankYouPage';
import ThankYouPageContacto from './components/main/ThankYouPageContacto';
import ScrollToTop from './components/ScrollToTop';
import appFirebase from './firebase/firebase';
/* import Mantenimiento from './components/mantenimiento/Mantenimiento'; */
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getRol } from './firebase/firebaseFunctions';
import { fetchRoles } from './redux/slices/adminSlice';

const auth = getAuth(appFirebase)

function App() {
  const dispatch = useDispatch()
  const { usuario } = useSelector((state) => state.users)
	const { listaRoles } = useSelector((state) => state.admin)

  // Credenciales de autenticación
  function setUserWithFirebaseAndRol(usuarioFirebase) {
    getRol(usuarioFirebase.uid).then(rol => {
      const userData = {
        uid: usuarioFirebase.uid,
        email: usuarioFirebase.email,
        rol: rol,
      }
      dispatch(setUsuario(userData))
      if(userData.rol==="admin"){
        dispatch(fetchProducts("admin"))
      }
      const rolActual = listaRoles && listaRoles.length>0 && listaRoles.find(r => r.id==userData.rol)
      if(rolActual && rolActual.descuento){
        dispatch(setDescuentoAplicado(rolActual.descuento))
      }
    })
  }

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if(usuarioFirebase){
      if(!usuario){
        setUserWithFirebaseAndRol(usuarioFirebase)
      }
      
    } else{
      dispatch(setUsuario(null))
      dispatch(setDescuentoAplicado(0))
    }

  })


  const listaProductos = useSelector((state) => state.products.listaProductos)
  const shouldFetch = useRef(true)
  useEffect(() => {
    async function startFetching() {
      if (shouldFetch.current && listaProductos.length === 0) {
        shouldFetch.current = false
        if(usuario && usuario.rol==="admin"){
          dispatch(fetchProducts("admin"))
        } else{
          dispatch(fetchProducts())
        }
        dispatch(fetchCategorias())
        dispatch(fetchRoles())
      }
    }
    if (listaProductos.length == 0) {
      startFetching();
    }
  }, []);

return (
  <BrowserRouter>
    <ScrollToTop/>
      {/* <Mantenimiento/> */}
      <Navbar/>

        <Routes>
          <Route path="/">
            
            <Route index element={<Main />} />
            <Route path="productos">
              <Route index element={<ProductoContainer />} />
              <Route path="busqueda">
                <Route path=":palabraClave" element={<ProductoContainer />} />
              </Route>
              <Route path=":categoria">
                <Route index element={<ProductoContainer />} />
                <Route path=":subcategoria/:subCategId" element={<ProductoContainer />} />
              </Route>
            </Route>
            <Route path="producto">
              <Route index element={<ProductoDetail />} />
              <Route path=":id" element={<ProductoDetail />} />
            </Route>
            <Route path="carrito" element={<Carrito />} />
            <Route path="contacto" element={<Contacto />} />
            <Route path="formulariocompra" element={<FormularioCompra />} />
            <Route path="finishedprocess" element={<ThankYouPage/>} />
            <Route path="finishedprocesscontacto" element={<ThankYouPageContacto/>} />
            
            <Route path="admin">
              <Route index element={<AdminDashboard />} />
              <Route path="crear_producto" element={<ProductoForm />} />
              <Route path="control_productos" element={<ControlProductos />} />
              <Route path="control_ventas" element={<ControlVentas />} />
              <Route path="control_usuarios" element={<ControlUsuarios />} />
              <Route path="control_categorias" element={<ControlCategorias />} />
              <Route path="control_roles" element={<ControlRoles />} />
            </Route>

          </Route>
        </Routes>

    <Footer/>
  </BrowserRouter>
);
}

export default App