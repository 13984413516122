import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllUsers } from '../../firebase/firebaseFunctions'

const initialState = {
    usuario: null,
    listaUsuarios: [],
    loading: false,
    error: null,
}

// Generates pending, fulfilled and rejected action types
export const fetchUsuarios = createAsyncThunk('users/fetchUsuarios', () => {
    return(getAllUsers())
})

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsuario: (state, action) => {
            state.usuario = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUsuarios.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchUsuarios.fulfilled, (state, action) => {
            state.loading = false
            state.listaUsuarios = action.payload
            state.error = ''
        })
        builder.addCase(fetchUsuarios.rejected, (state, action) => {
            state.loading = false
            state.listaUsuarios = []
            state.error = action.error.message
        })
    }
})

export default usersSlice.reducer
export const { setUsuario } = usersSlice.actions