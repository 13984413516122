import React from 'react'
import './Contacto.css';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import { alertaError, alertaExito } from '../Alertas/alertas';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    nombre: Yup.string()
        .max(40, 'Debe tener 40 caracteres o menos')
        .required('Requerido'),
    apellido: Yup.string()
        .max(40, 'Debe tener 40 caracteres o menos')
        .required('Requerido'),
    telefono: Yup.string()
        .max(20, "El maximo de caracteres es 20")
        .required('Requerido'),
    mail: Yup.string()
        .email("Debe tener un formato de email")
        .required('Requerido'),
    mensaje: Yup.string()
        .max(1000, 'Debe tener 1000 caracteres o menos')
        .min(20,'Debe tener 20 caracteres o mas' )
        .required('Requerido'),       
});

const Contacto = () => {

    const envioExitoso = () => {
        Swal.fire({
            icon: 'success',
            title: "Su mensaje fue enviado correctamente",
            showConfirmButton: false,
            timer: 2000
        })
    }

    const mailContacto = (values) => {
        const templateParams = {
            nombre: values.nombre,
            apellido: values.apellido,
            email: values.mail,
            telefono: values.telefono,
            mensaje: values.mensaje,
        };
        
        // console.log(templateParams)
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_CONTACTO_TEMPLATE_ID, 
            templateParams, 
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then((result) => {
            alertaExito("El mensaje se envió con éxito.");
        }, (error) => {
            alertaError("Ocurrió un error.");
        });
    }
    
    const navigate = useNavigate()
    const redireccionar = () => {
        navigate("/finishedprocesscontacto")
    }

    return (
        <div>
            <div className="contenedorform_contact">

                <br/>
                <br/>

                <div className="contenidoform_contact">

                    <div className="form_contact">
                        <h2 className='contactUs'>Contactanos</h2>
                        <Formik
                            initialValues={{
                                    nombre: "",
                                    apellido: "",
                                    telefono: "",
                                    mail: "",
                                    mensaje: ""
                                }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={values => {
                                // console.log(values)
                                envioExitoso()
                                mailContacto(values)
                                redireccionar()
                            }}
                        >
                        {({ errors, touched }) => (
                            <Form className='d-flex flex-column'>

                            <Row>
                                <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                        name="nombre"
                                        className={(touched.nombre && errors.nombre) ? 'input-invalido mb-3' : "mb-3"}
                                        placeholder="Nombre..."
                                    />
                                    <div>
                                        {touched.nombre && errors.nombre && <div className='text-danger mb-2'>{errors.nombre}</div>}
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                        name="apellido"
                                        className={(touched.apellido && errors.apellido) ? 'input-invalido mb-3' : "mb-3"}
                                        placeholder="Apellido..."
                                    />
                                    <div>
                                        {touched.apellido && errors.apellido && <div className='text-danger mb-2'>{errors.apellido}</div>}
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                        name="telefono"
                                        className={(touched.telefono && errors.telefono) ? 'input-invalido mb-3' : "mb-3"}
                                        placeholder="Teléfono..."
                                    />
                                    <div>
                                        {touched.telefono && errors.telefono && <div className='text-danger mb-2'>{errors.telefono}</div>}
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                        name="mail"
                                        className={(touched.mail && errors.mail) ? 'input-invalido mb-3' : "mb-3"}
                                        placeholder="Email..."
                                    />
                                    <div>
                                        {touched.mail && errors.mail && <div className='text-danger mb-2'>{errors.mail}</div>}
                                    </div>
                                </Col>
                                <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                        name="mensaje"
                                        as="textarea"
                                        className={(touched.mail && errors.mail) ? 'input-invalido mb-3' : "mb-3"}
                                        placeholder="Mensaje..."
                                    />
                                    <div>
                                        {touched.mensaje && errors.mensaje && <div className='text-danger mb-2'>{errors.mensaje}</div>}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} className="pt-3">
                                        <button type="submit">
                                            Enviar
                                        </button>
                                </Col>
                            </Row>

                            </Form>
                                )}
                            </Formik>

                    </div>

                    <div className="form_info_contact">

                        <h2 className='masInformacion'>Mas información</h2>
                        <ul style={{listStyle:"none"}}>
                            <li> <i className="fa-solid fa-location-dot"></i> <b>Cnel. Pablo Zufriategui 794, Ituzaingó Norte.</b> </li>
                                  
                            <li> <i className="fa-solid fa-square-phone"></i> <b>4458-3224</b> </li>
                            <li> <i className="fa-solid fa-envelope"></i> <b>gecedatatienda@gmail.com</b> </li>
                        </ul>
                        
                        <p className='information'>
                            Le agradecemos su consulta. Nos contactaremos en la brevedad con usted. 
                        </p>
                        
                        <p className='information'>
                            <b>www.gecedata.com.ar</b>
                        </p>

                    </div>

                </div>
            </div> 
        
        </div>
    )
}

export default Contacto
