import React, { useEffect, useState } from 'react'
import './ControlProductos.css';
import { Button, Container, Modal, Form, InputGroup } from 'react-bootstrap'
import { useNavigate, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPenToSquare, faTrash, faEye, faSquareCheck, faSquareXmark, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { deletedProduct, modProduct } from '../../redux/slices/productsSlice';
import { setEditOpen } from '../../redux/slices/adminSlice'
import ProductoForm from './ProductoForm';
import { deleteItem, editItem } from '../../firebase/firebaseFunctions';
import Swal from 'sweetalert2'

const ControlProductos = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { listaProductos } = useSelector((state) => state.products)
    const { modalEditOpen } = useSelector((state) => state.admin)
    const { usuario } = useSelector((state) => state.users)


    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState([])
    const [palabraBusc, setPalabraBusc] = useState("")
    const [listaFiltrada, setListaFiltrada] = useState(listaProductos ? listaProductos : [])
    
    const [modalEditContent, setModalEditContent] = useState([])
    
    const formatoprecio = (n) => (n ? n.toLocaleString("de-DE") : '')
    
    const handleClose = () => {
        setModalOpen(false)
        setModalContent([])
    }
    
    const handleOpen = (p) => {
        setModalContent(p)
        setModalOpen(true)
    }

    const handleEditClose = () => {
        dispatch(setEditOpen(false))
        setModalEditContent([])
    }

    const handleEditOpen = (p) => {
        dispatch(setEditOpen(true))
        setModalEditContent(p)
    }
    
    const deleteConfirmation = (id, titulo) => {
        Swal.fire({
            title: `¿Seguro que deseas borrar el producto: ${titulo}?`,
            text: "Esta acción es irreversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar',
            cancelButtonText: 'No, Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItem(id)
                dispatch(deletedProduct(id))
                Swal.fire(
                'Listo',
                `Producto eliminado con éxito`,
                'success'
                )
            }
        })
    }

    const cambiarVisibilidad = (p) => {
        if(p.visible==undefined || p.visible==false){
            editItem(([{...p, visible: true}].map(({ id, ...rest }) => rest))[0], p.id)
            dispatch(modProduct({...p, visible: true}))
        } else if(p.visible==true){
            editItem(([{...p, visible: false}].map(({ id, ...rest }) => rest))[0], p.id)
            dispatch(modProduct({...p, visible: false}))
        }
    }

    useEffect(() => {
        const lista = listaProductos.filter(str => (str.id && str.id.includes(palabraBusc)) || (str.titulo && str.titulo.includes(palabraBusc)) || (str.descripcion && str.descripcion.includes(palabraBusc)) || (str.categoria && str.categoria.includes(palabraBusc)) || (str.subcategoria && str.subcategoria.includes(palabraBusc)) || (str.subCategId && str.subCategId.includes(palabraBusc)))
        if(lista!==listaFiltrada){
            setListaFiltrada(lista)
            if(palabraBusc===""){
                setListaFiltrada(listaProductos)
            }
        }
    }, [palabraBusc, listaProductos])
    
    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <>
            <Button 
                variant="danger" 
                className='ms-3 mt-3' 
                style={{position: "absolute"}}
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Container>
                <p className='fs-4 mt-5'>Gestión de Productos</p>
                <p className='fs-6 border border-0 my-3'>En este sector se pueden: Crear nuevos productos, editar los existentes (Cambiar precios, stock, descripciones, titulos, ofertas), ocultarlos/mostrarlos y eliminarlos.</p>
                <p>Nota: Puedes buscar por: Id (Exacta), Titulo, Descripcion, Categoria o Subcategoria</p>
            </Container>
            <Container>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Buscador"
                    aria-label="Buscador"
                    onChange={(e) => setPalabraBusc(e.target.value)}
                    />
                </InputGroup>
            </Container>
            <Container>
                <Table striped bordered className='shadow-lg'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th>Stock</th>
                            <th>Visible</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaFiltrada.length>0 && listaFiltrada.map((p) => (
                            <tr key={p.id}>
                                <td>{p.id}</td>
                                <td>{p.titulo}</td>
                                <td>${formatoprecio(p.precio)}</td>
                                <td>{p.stock}</td>
                                <td>{(p.visible) ? <div className='d-flex justify-content-center align-items-center'><FontAwesomeIcon className='fa-xl visibilidadTrue' icon={faSquareCheck}/></div> : <div className='d-flex justify-content-center align-items-center'><FontAwesomeIcon className='fa-xl visibilidadFalse' icon={faSquareXmark}/></div>}</td>
                                <td>
                                    <Button onClick={() => handleOpen(p)}>
                                        <FontAwesomeIcon icon={faInfo} />
                                    </Button>{' '}
                                    <Button variant='warning' onClick={() => handleEditOpen(p)}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                    </Button>{' '}
                                    <Button variant='secondary' onClick={() => cambiarVisibilidad(p)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>{' '}
                                    <Button variant='danger' onClick={() => deleteConfirmation(p.id, p.titulo)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

            <Modal size="lg" show={modalOpen} onHide={handleClose} className='text-center '>
                <Modal.Header closeButton className='fs-5 ContenedorModal'>
                    Ver detalle ID: #{modalContent.id}
                </Modal.Header>
                
                <Modal.Body className='ContenedorModal' >
                    <div className='row m-5'>        
                        
                        <div className='col'>
                            <div className='fw-bold justify-content-start p-2 TextoControlProductos'>Producto: </div> 
                            <p> {modalContent.titulo} </p>
                        </div>
                        
                        <div className='col'>
                            <div className='fw-bold justify-content-center p-2 TextoControlProductos'>Precio: </div>
                            <p> ${formatoprecio(modalContent.precio)} </p>
                        </div>
                        
                        <div className='col'>
                            <div className='fw-bold justify-content-end p-2 TextoControlProductos'>Stock: </div>
                            <p> {modalContent.stock} </p>
                        </div>
                    
                    </div>
                    <div className='row m-5'>
                        
                       
                        <div className='col'>
                            <div className='fw-bold justify-content-center p-2 TextoControlProductos'>Categoría: </div>
                            <p className='text-capitalize'> {modalContent.categoria} </p>
                        </div>
                       
                        <div className='col'>
                            <div className='fw-bold justify-content-end p-2 TextoControlProductos'>Subcategoría: </div>
                            <p className='text-capitalize'> {modalContent.subcategoria} </p>
                        </div>   
                        <div className='col'>
                            <div className='fw-bold justify-content-start p-2 TextoControlProductos'>Id de Subcategoría: </div> 
                            <p className='text-capitalize'> {modalContent.subCategId} </p>                       
                        </div>
                    
                    </div>   
                    <div className='row m-5'>
                        
                         <div className='col'>
                            <div className='fw-bold DescripcionControl justify-content-start p-2 TextoControlProductos'>Descripción: </div>
                            <p> {modalContent.descripcion} </p>
                        </div>
                                                                  
                    </div>    
                    <div className='row m-5'>
                        
                         <div className='col'>
                            <div className='fw-bold DescripcionControl justify-content-start p-2 TextoControlProductos'>Oferta: </div>
                            <p>{modalContent.oferta ? "SI" : "NO"}</p>
                        </div>
                    
                        <div className='col'>
                            <div className='fw-bold DescripcionControl justify-content-start p-2 TextoControlProductos'>Código de barra: </div>
                            <p>{(modalContent.codigo_barra && modalContent.codigo_barra!=="") ? modalContent.codigo_barra : "No tiene"}</p>
                        </div>
                                                                  
                    </div>   
                    <div className='row m-5'>
                                                                                
                       <div className='col'>
                            <div className='fw-bold justify-content-end p-2 TextoControlProductos'>
                                Imagen: 
                                {modalContent.imagen && modalContent.imagen!=="" ?
                                    <img className="FotoControlProductos" src={modalContent.imagen} alt={modalContent.descripcion}/>
                                    :
                                    <img className="FotoControlProductos" src={`${process.env.PUBLIC_URL}/img/producto_sin_imagen.png`} alt={modalContent.descripcion}/>
                                }
                            </div>
                        </div>
                        
                    </div>    
                        
                                                      
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={modalEditOpen} onHide={handleEditClose}>
                <Modal.Header closeButton className='fs-5'>
                    Editar producto
                </Modal.Header>
                <Modal.Body>
                    <ProductoForm valoresEdicion={modalEditContent} handleEditClose={handleEditClose}  />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ControlProductos