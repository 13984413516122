import React, { useEffect } from 'react'
import "./Carrito.css"
import { Button, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CarritoItem from './CarritoItem'
import { fetchProducts } from '../../../redux/slices/productsSlice'

const Carrito = () => {
    const dispatch = useDispatch()
    const { carrito, totalAPagar, descuentoAplicado } = useSelector((state) => state.products)

    useEffect(() => {
        dispatch(fetchProducts())
    }, [])

    const formatoprecio = (n) => (n ? n.toLocaleString("de-DE") : '')

    return (
        <div className="my-3 mx-4" style={{backgroundColor: "white"}}>
            <h2 className='pt-3 pb-1 px-4'>Carrito</h2>
            <hr/>
            {carrito && carrito.length>0 ?
                <>
                    {carrito.map( p => (
                        <CarritoItem key={p.id} p={p} />
                    ))}
                    <div className='py-4 mb-3 me-4 d-flex justify-content-end'>
                        <h4 className='finCarrito'>Total: ${descuentoAplicado ? formatoprecio(totalAPagar*((100-descuentoAplicado)/100)) : formatoprecio(totalAPagar)}</h4>
                    </div>
                    
                    <div className='py-4 mb-3 me-4 d-flex justify-content-end finCarrito'>
                        <Link to="/formulariocompra">
                            <Button variant="primary" size="lg">
                                Comprar
                            </Button>
                        </Link>
                    </div>
                </>
            :
            <div className='px-5'>
                <Alert key="primary" variant="danger" className='noProducts'>
                    No hay productos en el carrito
                </Alert>
                <div className='py-4 mb-3 me-4 d-flex justify-content-center'>
                    <Link to="/">
                        <Button size="md" className='homeButton'>Volver al Home</Button>
                    </Link>
                </div>
            </div>
            }

        </div>
    )
}

export default Carrito