import React, { useEffect, useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import ProductoGrid from './ProductoGrid'
import './ProductoContainer.css'
import '../Sidemenu.css'
import { useParams, Link, useLocation } from 'react-router-dom'
import Sidemenu from '../Sidemenu'
import SideMenuResponsive from '../SideMenuResponsive'
import { useSelector, useDispatch } from 'react-redux'
import { setPalabraBuscada } from '../../../redux/slices/productsSlice'


const ProductoContainer = () => {
  const { categoria, palabraClave } = useParams()
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  const { palabraBuscada } = useSelector((state) => state.products)
  const [sideMenuResp, setSideMenuResp] = useState(false)
  const handleCloseMenu = () => {
    setSideMenuResp(false)
  }

  useEffect(() => {
    if(palabraBuscada===""){
      dispatch(setPalabraBuscada(palabraClave))
    }
  }, [palabraBuscada])
  

  return (
    <div className='d-flex flex-row'>
      {pathname.includes("/productos/busqueda/")
      ?
      <Container>
        <div className='d-flex flex-column'>
          <h5 className='mb-3'>Resultados para "<span className='text-capitalize'>{palabraBuscada}</span>"</h5>
            <ProductoGrid />
        </div>
      </Container>
      :
      <>
        {(categoria!==undefined && categoria!=="ofertas") ? <div className="side-menu-comun"><Sidemenu /></div> : <></> }
        <Container>
            <div className="d-flex row_container">
              <h2 className='text-capitalize nombre-categoria-resp'>{ categoria }</h2>
              { pathname !== "/" ? 
                <Button className='side-menu-responsive ms-5 BotonFiltrar ms-auto p-2' onClick={() => setSideMenuResp(true)}>Filtrar</Button>
              : <></>
              }
              <h2 className="offer text-capitalize">{categoria!==undefined ? <></> : "Ofertas"}</h2>
              {categoria!==undefined ? <></> : <Link to="/productos/ofertas" style={{color: "rgba(65,137,500,.7)"}}>Ver más</Link>}
            </div>
          <ProductoGrid/>
        </Container>
        <SideMenuResponsive className="side-menu-responsive" sideMenuResp={sideMenuResp} handleCloseMenu={handleCloseMenu}/>
      </>
      }
      
    </div>
  )
}

export default ProductoContainer