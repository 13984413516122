import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Counter from '../Counter'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromCart } from '../../../redux/slices/productsSlice'
import "./CarritoItem.css"

const CarritoItem = (props) => {
    const dispatch = useDispatch()
    const { descuentoAplicado } = useSelector((state) => state.products)
    const { p } = props

    const [cantidad, setCantidad] = useState(p.cantidad)
    const [stockLimit, setStockLimit] = useState(p.stock)
    
    function formatoprecio(n) {
        return n.toLocaleString("de-DE");
    }
    return (
        <div className="border-bottom">
            <Row  className="mx-0 py-3" style={{width: "100%"}}>
                <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='d-flex justify-content-center align-items-start'>
                        {p.imagen && p.imagen!=="" ?
                            <img className="imagen_carrito" src={p.imagen} alt={p.descripcion}/>
                            :
                            <img className="imagen_carrito" src={`${process.env.PUBLIC_URL}/img/producto_sin_imagen.png`} alt={p.descripcion}/>
                        }
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='itemCarrito'>
                        <h2 className='fs-5 pt-2'>{p.titulo}</h2>
                    </div>
                    <div className='itemCarrito'>
                        <p className='fs-6 pt-2 px-0 text-capitalize text-muted border border-0'>{p.categoria}</p>
                    </div>
                    <div className='eliminarCarrito'>
                        <p 
                            className='boton_eliminar_carrito text-start fs-6 text-primary text-decoration-underline border border-0'
                            onClick={() => dispatch(removeFromCart({id: p.id, cantidad: cantidad, precio: p.precio}))}
                        >
                            Eliminar
                        </p>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='d-flex justify-content-center align-items-start counterProduct'>
                        <Counter 
                            cantidad={cantidad} 
                            setCantidad={setCantidad} 
                            id={p.id}
                            precio={p.precio}
                            stock={p.stock}
                            stockLimit={stockLimit}
                            setStockLimit={setStockLimit}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='d-flex justify-content-center align-items-start precioProduct'>
                        <p className='fs-4 pt-2 pe-2 text-end fw-normal'>${descuentoAplicado ? formatoprecio((p.precio*((100-descuentoAplicado)/100) * p.cantidad)) : (formatoprecio(p.precio * p.cantidad))}</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default CarritoItem