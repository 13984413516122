import { registrarUsuarioFS } from "../../../firebase/firebaseFunctions";
import { signOut } from "firebase/auth";
import { loginExito, loginFallido } from "./LoginForm";
import { alertaError, alertaExito } from "../../Alertas/alertas";

export async function registrar(createUser, auth, email, password, role, nombre, apellido, cuit){
    const infoUsuario = await createUser(auth, email, password)
    .then((usuarioFirebase) => {
        return usuarioFirebase;
    })
    .catch((error) => {
        /* const errorCode = error.code;
        const errorMessage = error.message; */
        // console.log("Codigo: ", errorCode)
        // console.log("Mensaje: ", errorMessage)
    });

    registrarUsuarioFS(infoUsuario.user.uid, email, role, nombre, apellido, cuit)
}

export async function login(signInUser, auth, email, password){
    const user = await signInUser(auth, email, password)
    .catch((error) => {
        if(error.code==="auth/wrong-password"){
            loginFallido("contraseña")
        } else if(error.code==="auth/user-not-found"){
            loginFallido("usuario")
        }
    })
    if(user!==undefined){
        loginExito()
    }
}

export async function cerrarSesion(auth){
    signOut(auth).then(() => {
        alertaExito("Has cerrado sesión.")
    }).catch((error) => {
        alertaError("Ocurrió un error: ", error)
    });
}