import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Table, Modal, Row, Col } from 'react-bootstrap'
import { useNavigate, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSquareCheck, faSquareXmark, faTrash} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { editCategorias } from '../../firebase/firebaseFunctions';
import { fetchCategorias, setCategorias } from '../../redux/slices/productsSlice';
import CategoriasForm from './CategoriasForm';
import Swal from 'sweetalert2';

const ControlCategorias = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { usuario } = useSelector((state) => state.users)
    const { categorias } = useSelector((state) => state.products)
    const { listaVentas } = useSelector((state) => state.admin)

    const [modalOpen, setModalOpen] = useState(false)
    const [categoriaPadre, setCategoriaPadre] = useState("")
    const [subcategoriaPadre, setSubcategoriaPadre] = useState("")

    const deleteConfirmationSubcateg = (categoria, subcateg) => {
        Swal.fire({
            title: `¿Seguro que deseas borrar la subcategoria ${subcateg} que pertenece a la categoria '${categoria}'?`,
            text: "Esta acción es irreversible y borrará todas los IDs de subcategoria que tiene anidada esta subcategoría.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar',
            cancelButtonText: 'No, Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("Deleted", subcateg)
                const categoriasModif = categorias.map(c => c.nombre===categoria ?
                    ({...c, subfiltros: c.subfiltros.filter(subc => subc.nombre!==subcateg)}) 
                    : (c))
                editCategorias(categoriasModif)
                dispatch(setCategorias(categoriasModif))
                Swal.fire(
                'Listo',
                `Subcategoría eliminada con éxito`,
                'success'
                )
            }
        })
    }

    const deleteConfirmationSubcategId = (categoria, subcateg, subcategId) => {
        Swal.fire({
            title: `¿Seguro que deseas borrar la subcategoria ID '${subcategId}' que pertenece a la subcategoria '${subcateg}' y a la categoria '${categoria}'?`,
            text: "Esta acción es irreversible.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar',
            cancelButtonText: 'No, Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("Deleted", subcategId)
                const categoriasModif = categorias.map(c => c.nombre===categoria ?
                    ({...c, subfiltros: c.subfiltros.map(subc => subc.nombre===subcateg ?
                        ({...subc, lista: subc.lista.filter(subcId => subcId.nombre!==subcategId)})
                    : (subc))})
                : (c)
                )
                editCategorias(categoriasModif)
                dispatch(setCategorias(categoriasModif))
                Swal.fire(
                'Listo',
                `Subcategoría ID eliminada con éxito`,
                'success'
                )
            }
        })
    }

    const handleVisibilidadSubcateg = (visibleStatus, categoria, subcateg) => {
        const categoriasModif = categorias.map(c => c.nombre===categoria ?
            ({...c, subfiltros: c.subfiltros.map(subc => subc.nombre===subcateg ?
                ({...subc, visible: visibleStatus})
            : (subc))})
        : (c)
        ) 
        editCategorias(categoriasModif)
        dispatch(setCategorias(categoriasModif))
    }

    const handleVisibilidadSubcategId = (visibleStatus, categoria, subcateg, subcategId) => {
        const categoriasModif = categorias.map(c => c.nombre===categoria ?
            ({...c, subfiltros: c.subfiltros.map(subc => subc.nombre===subcateg ?
                ({...subc, lista: subc.lista.map(subcId => subcId.nombre===subcategId ?
                    ({...subcId, visible: visibleStatus})
                    : (subcId)
                )})
            : (subc))})
        : (c)
        )
        editCategorias(categoriasModif)
        dispatch(setCategorias(categoriasModif))
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setCategoriaPadre("")
        setSubcategoriaPadre("")
    }

    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const shouldFetch = useRef(true)
    useEffect(() => {
        async function startFetching() {
            if (shouldFetch.current && listaVentas.length===0) {
                shouldFetch.current = false
                dispatch(fetchCategorias())
            }
        }
        if (listaVentas.length===0) {
            startFetching();
        }
    }, []);

    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <>
            <Button 
                variant="danger" 
                className='ms-3 mt-3' 
                style={{position: "absolute"}}
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Container>
                <p className='fs-4 mt-5'>Gestión de Categorías</p>
                <p className='fs-6 border border-0 my-3'>En este sector se pueden: Crear nuevas categorías, editar las existentes (Cambiar nombres, visibilidad) y eliminarlas.</p>
            </Container>
            <Container>
                {(categorias && categorias.length!=0) ?
                <>
                    {categorias && categorias.map(c => (
                    <div key={c.nombre} className='mb-3 border border-primary p-3'>
                        <h5 className='text-capitalize'>Categoría: <span className='fw-bold'>{c.nombre}</span></h5>
                        <Button variant='warning' onClick={() => {handleModalOpen(); setCategoriaPadre(c.nombre)}}>
                            Crear Subcategoría
                        </Button>
                        <Row>
                        {c.subfiltros && c.subfiltros.map(subc => (
                            <Col key={subc.nombre} xs={6} className='my-4 border border-secondary p-2'>
                                <div className='d-flex justify-content-center align-items-center mb-2'>
                                    <h6 className='text-capitalize me-3'>Subcategoría: <span className='fw-bold'>{subc.nombre}</span> {subc.visible ? "(VISIBLE)" : "(OCULTA)"}</h6>
                                    <Button className='me-2' variant={subc.visible ? 'danger' : 'success'} onClick={() => handleVisibilidadSubcateg(!subc.visible, c.nombre, subc.nombre)}>
                                        {subc.visible ? "Ocultar" : "Mostrar"}
                                    </Button>
                                    <Button className='me-2' variant='warning' onClick={() => {handleModalOpen(); setCategoriaPadre(c.nombre); setSubcategoriaPadre(subc.nombre)}}>
                                        Crear
                                    </Button>
                                    <Button variant='danger' onClick={() => deleteConfirmationSubcateg(c.nombre, subc.nombre)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                                {subc.lista && subc.lista.length>0 ?
                                <Table striped bordered className='shadow-sm'>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Visible</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subc.lista.length>0 && subc.lista.map((s) => (
                                            <tr key={s.nombre}>
                                                <td>{s.nombre}</td>
                                                <td>{(s.visible) ? <div className='d-flex justify-content-center align-items-center'><FontAwesomeIcon className='fa-xl visibilidadTrue' icon={faSquareCheck}/></div> : <div className='d-flex justify-content-center align-items-center'><FontAwesomeIcon className='fa-xl visibilidadFalse' icon={faSquareXmark}/></div>}</td>
                                                <td>
                                                    <Button variant={s.visible ? 'danger' : 'success'} onClick={() => handleVisibilidadSubcategId(!s.visible, c.nombre, subc.nombre, s.nombre)}>
                                                        {s.visible ? "Ocultar" : "Mostrar"}
                                                    </Button>{' '}
                                                    <Button variant='danger' onClick={() => deleteConfirmationSubcategId(c.nombre, subc.nombre, s.nombre)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                : <div className='d-flex justify-content-center align-items-start pt-3 fw-bold'>No hay IDs de Subcategoría</div>
                                }
                            </Col>
                        ))}
                        </Row>
                    </div>
                    ))}
                    </>
                    : <></>
                }
            </Container>

            <Modal size="lg" show={modalOpen} onHide={handleModalClose}>
                <Modal.Header closeButton className='fs-5'>
                    {subcategoriaPadre=="" ? `Crear Subcategoría para '${categoriaPadre}'` : `Crear Item de Subcategoría para '${categoriaPadre}'->'${subcategoriaPadre}'`}
                </Modal.Header>
                <Modal.Body>
                    <CategoriasForm handleModalClose={handleModalClose} categoriaPadre={categoriaPadre} subcategoriaPadre={subcategoriaPadre}/>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ControlCategorias