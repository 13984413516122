import React from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { editRoles } from '../../firebase/firebaseFunctions'
import { useDispatch, useSelector } from 'react-redux';
import { setRoles } from '../../redux/slices/adminSlice';
import { alertaError, alertaExito } from '../Alertas/alertas';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    descuento: Yup.number()
        .min(0, 'Debe ser mayor a Cero')
        .max(99, 'Debe ser menor o igual a 99')
        .required('Requerido'),
});

const RolesForm = (props) => {
    const dispatch = useDispatch();
    const { rolAEditar, handleEditClose } = props
    const { usuario } = useSelector((state) => state.users)
    const { listaRoles } = useSelector((state) => state.admin)

    const handleSubmitEdit = (values) => {
        try {
            editRoles(listaRoles.map(r => r.id==rolAEditar.id ? {...r, descuento: Number(values.descuento)} : r))
            dispatch(setRoles(listaRoles.map(r => r.id==rolAEditar.id ? {...r, descuento: values.descuento} : r)))
            handleEditClose()
            alertaExito(`Descuento modificado con éxito para el rol "${rolAEditar.nombre}"`)
        } catch (error) {
            alertaError(error)
            handleEditClose()
        }
    }

    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <Container>
            <Formik
            initialValues={ 
                {
                    descuento: rolAEditar.descuento,
                }
            }
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={values => {
                handleSubmitEdit(values)
            }}
            >
                {({errors, touched }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="descuento">Descuento:</label>
                            <Field name="descuento" className={(touched.descuento && errors.descuento) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.descuento && errors.descuento && <div className='text-danger mb-2'>{errors.descuento}</div>}
                        </div>
                        
                        <Button type="submit">Editar Descuento</Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default RolesForm