import React, { useState } from 'react'
import DetalleCompra from './DetalleCompra';
import './FormularioCompra.css';
import { Row, Col, Alert, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { crearOrdenDeCompra } from '../../../firebase/firebaseFunctions';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, setOrdenCompra } from '../../../redux/slices/productsSlice';

export const parsearFechaLocal = (fecha) => {
    const [month, day, year] = [fecha.getMonth(), fecha.getDate(), fecha.getFullYear()];
    const [hour, minutes] = [fecha.getHours(), fecha.getMinutes()];
    return(`${day}/${month+1}/${year} ${hour}:${minutes<=9 ? "0" : ""}${minutes}`)
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
    nombre: Yup.string()
        .max(40, 'Debe tener 40 caracteres o menos')
        .required('Requerido'),
    apellido: Yup.string()
        .max(40, 'Debe tener 40 caracteres o menos')
        .required('Requerido'),
    telefono: Yup.string()
        .max(20, "El maximo de caracteres es 20")
        .required('Requerido'),
    mail: Yup.string()
        .email("Debe tener un formato de email")
        .required('Requerido'),
});

const FormularioCompra = () => {
    const dispatch = useDispatch()
    const carrito = useSelector((state) => state.products.carrito)
    const { usuario } = useSelector((state) => state.users)
    const { listaRoles } = useSelector((state) => state.admin)

    const [loading, setLoading] = useState(false)
    
    const creacionExitosa = () => {
        Swal.fire({
            icon: 'success',
            title: "Se creó la orden de compra con éxito",
            showConfirmButton: false,
            timer: 2000
        })
    }
    
    const navigate = useNavigate()
    const redireccionar = () => {
        navigate("/finishedprocess")
    }

    const formatearCarrito = (carrito) => {
        carrito = carrito.map(p => ({...p, totalProducto: (p.precio*p.cantidad)}))
        return carrito
    }

    const formatearOrden = (dataInicial) => {
        const status = "pendiente"
        let rol = "consumidor final"
        let descuento = 0
        if(usuario){
            rol = usuario.rol
            descuento = listaRoles && listaRoles.find(f => f.id===usuario.rol).descuento
        }
        let subtotal = 0
        dataInicial.productos.forEach(p => {
            subtotal += p.precio * p.cantidad
        })
        const totalFinal = descuento>0 ? Math.round(subtotal*(1-(descuento/100))) : subtotal

        const fecha = parsearFechaLocal(new Date())

        return({...dataInicial, status, rol, descuento, subtotal, totalFinal, fecha})
    }


return (
    <>
    {carrito && carrito.length>0 ?
        <div className="contenedorform_compra">

            <br/>
            <br/>

            <div className="contenidoform_compra">

                <div className="form_contact">

                    {!loading ? 
                    <>
                        <h2>Orden de Compra</h2>
                        <p>Complete con sus datos y a la brevedad un vendedor lo contactará para continuar con la compra</p>
                        <Formik
                            initialValues={{
                                    nombre: "",
                                    apellido: "",
                                    telefono: "",
                                    mail: ""
                                }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={async (values) => {
                                setLoading(true)
                                const nroOrden = await crearOrdenDeCompra(formatearOrden({...values, productos: formatearCarrito(carrito)}))
                                dispatch(setOrdenCompra({...values, nroOrden: nroOrden, productos: formatearCarrito(carrito)}))
                                creacionExitosa()
                                dispatch(clearCart())
                                redireccionar()
                            }}
                        >
                        {({ errors, touched }) => (
                                <Form className='d-flex flex-column'>
                                
                            <Row>
                                <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                        name="nombre"
                                        className={(touched.nombre && errors.nombre) ? 'input-invalido mb-3' : "mb-3"}
                                        placeholder="Nombre..."
                                    />
                                    <div>
                                        {touched.nombre && errors.nombre && <div className='text-danger mb-2'>{errors.nombre}</div>}
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                        name="apellido"
                                        className={(touched.apellido && errors.apellido) ? 'input-invalido mb-3' : "mb-3"}
                                        placeholder="Apellido..."
                                    />
                                    <div>
                                    {touched.apellido && errors.apellido && <div className='text-danger mb-2'>{errors.apellido}</div>}
                                    </div>
                                    </Col>
                                    <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                    <Field 
                                    name="telefono"
                                    className={(touched.telefono && errors.telefono) ? 'input-invalido mb-3' : "mb-3"}
                                    placeholder="Teléfono..."
                                    />
                                    <div>
                                        {touched.telefono && errors.telefono && <div className='text-danger mb-2'>{errors.telefono}</div>}
                                    </div>
                                </Col>
                                <Col xs={6} className="d-flex flex-column justify-content-center align-items-center">
                                <Field 
                                name="mail"
                                className={(touched.mail && errors.mail) ? 'input-invalido mb-3' : "mb-3"}
                                placeholder="Email..."
                                    />
                                    <div>
                                    {touched.mail && errors.mail && <div className='text-danger mb-2'>{errors.mail}</div>}
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs={12} className="pt-3">
                                    <button type="submit">
                                    Enviar
                                    </button>
                                </Col>
                            </Row>

                            </Form>
                            )}
                        </Formik>
                    </>

                    : 
                        <div className='d-flex justify-content-center align-items-center'>
                            <Spinner className='mt-4 me-3' size="xl" animation="border"/>
                            <h5 className='mt-4'>Cargando</h5>
                        </div>
                    }
                        </div>
                        
                        <div className="form_info">
                    <h2 className='detalleCompra' style={{textAlign: "center"}}>Detalle de la compra</h2> 
                    <DetalleCompra/>
                </div>

            </div>
        </div>
    : 
        <div className='px-5 mt-5'>
            <Alert key="primary" variant="primary" className='noProducts'>
                No hay productos en el carrito
            </Alert>
            <div className='py-4 mb-3 me-4 d-flex justify-content-center'>
                <Link to="/">
                    <Button size="md">Volver al Home</Button>
                </Link>
            </div>
        </div>
    }
    </>
  )
}

export default FormularioCompra
