import React from 'react'
import './AdminDashboard.css';
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBoxesStacked, faTableList, faUsers, faList } from '@fortawesome/free-solid-svg-icons';
import { Link, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminDashboard = () => {
    const { usuario } = useSelector((state) => state.users)
    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }

    return (
        <Container>
            <p className='fs-4'>Admin Dashboard</p>
            <Row className='mt-4'>
                <Col xs={3} className="mb-3">
                    <Link to="crear_producto">
                        <Card
                            bg="primary"
                            text="white"
                            className="mb-2 dashboard_card"
                            >
                            <Card.Body>
                                <Card.Title className='text-center'>
                                    Crear Producto {' '}
                                    <FontAwesomeIcon icon={faPlus} />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="control_productos">
                        <Card
                            bg="warning"
                            text="white"
                            className="mb-2 dashboard_card"
                            >
                            <Card.Body>
                                <Card.Title className='text-center'>
                                    Control de Productos {' '}
                                    <FontAwesomeIcon icon={faBoxesStacked} />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="control_ventas">
                        <Card
                            bg="success"
                            text="white"
                            className="mb-2 dashboard_card"
                            >
                            <Card.Body>
                                <Card.Title className='text-center'>
                                    Lista de ventas {' '}
                                    <FontAwesomeIcon icon={faTableList} />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="control_categorias">
                        <Card
                            bg="danger"
                            text="white"
                            className="mb-2 dashboard_card"
                            >
                            <Card.Body>
                                <Card.Title className='text-center'>
                                    Lista de Categorías{' '}
                                    <FontAwesomeIcon icon={faList} />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="control_usuarios">
                        <Card
                            bg="info"
                            text="white"
                            className="mb-2 dashboard_card"
                            >
                            <Card.Body>
                                <Card.Title className='text-center'>
                                    Lista de Usuarios {' '}
                                    <FontAwesomeIcon icon={faUsers} />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="control_roles">
                        <Card
                            bg="secondary"
                            text="white"
                            className="mb-2 dashboard_card"
                            >
                            <Card.Body>
                                <Card.Title className='text-center'>
                                    Lista de Roles{' '}
                                    <FontAwesomeIcon icon={faUsers} />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminDashboard