import React from 'react'
import './Navbar.css';
import { ListGroup, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MenuResponsive = (props) => {
    return (
        <Offcanvas show={props.menuOpen} onHide={props.handleCloseMenu}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <ListGroup>
                <Link to="/">
                    <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                            <b>Home</b>
                    </ListGroup.Item>
                </Link>
                <Link to="/productos/hardware">
                    <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                        <b>Productos</b>
                    </ListGroup.Item>
                </Link>
                <Link to="/contacto">
                    <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                        <b>Contacto</b>
                    </ListGroup.Item>
                </Link>
                <ListGroup.Item>
                    <b>Categorías</b>
                    <ListGroup variant="flush" className='mt-3 ms-1'>
                        <Link to="/productos/hardware">
                            <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                                Hardware
                            </ListGroup.Item>
                        </Link>
                        <Link to="/productos/audio">
                            <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                                Audio
                            </ListGroup.Item>
                        </Link>
                        <Link to="/productos/almacenamiento">
                            <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                                Almacenamiento
                            </ListGroup.Item>
                        </Link>
                        <Link to="/productos/insumos">
                            <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                                Insumos
                            </ListGroup.Item>
                        </Link>
                        <Link to="/productos/impresion">
                            <ListGroup.Item onClick={() => props.handleCloseMenu && props.handleCloseMenu()} action>
                                    Impresion
                            </ListGroup.Item>
                        </Link>
                    </ListGroup>
                </ListGroup.Item>
            </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default MenuResponsive