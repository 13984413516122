import React, { useEffect, useState } from 'react'
import './ProductoForm.css';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Button } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { createItemWithId, editItem } from '../../firebase/firebaseFunctions'
import { useDispatch, useSelector } from 'react-redux';
import { newProduct, modProduct } from '../../redux/slices/productsSlice';
import { alertaError } from '../Alertas/alertas';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    id: Yup.string()
        .required('Requerido'),
    codigo_barra: Yup.number(),
    titulo: Yup.string()
        .max(50, 'Debe tener 50 caracteres o menos')
        .required('Requerido'),
    descripcion: Yup.string() ,
    imagen: Yup.string()
        /* .required('Requerido') */,
    precio: Yup.number()
        .positive('')
        .required('Requerido'),
    stock: Yup.number()
        .min(0, 'Debe ser 0 o mayor')
        .required('Requerido'),
    oferta: Yup.boolean()
        .required('Requerido'),
    categoria: Yup.string()
        .required('Requerido'),
    /* subcategoria: Yup.string()
        .max(30, 'Debe tener 30 caracteres o menos'),
    subCategId: Yup.string()
        .max(30, 'Debe tener 30 caracteres o menos'), */
});

const ProductoForm = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const valoresEdicion = props.valoresEdicion
    const { listaProductos, categorias } = useSelector((state) => state.products)
    const { usuario } = useSelector((state) => state.users)

    const [listaIds, setListaIds] = useState([])

    useEffect(() => {
        // console.log(listaProductos)
        if(listaProductos && listaProductos.length>0){
            const idMapeados = listaProductos.map(item => item.id)
            setListaIds(idMapeados)
        }
    }, [listaProductos])
    
    const regresar = () => {
        navigate(-1)
    }

    const handleSubmitCreate = (values) => {
        const categ = values.categoria.split(" ~ ")
        values = {...values, categoria: categ[0], subcategoria: categ[1], subCategId: categ[2]}
        // console.log(values)
        if(values.codigo_barra===undefined){values.codigo_barra=''}
        const valuesFormateado = {
            ...values, 
            precio: Number(values.precio), 
            stock: parseInt(values.stock), 
            categoria: values.categoria.toLowerCase(),
            oferta: values.oferta
        }
        if(values.subcategoria=="" || values.subcategoria==undefined || values.subCategId=="" || values.subCategId==undefined){
            try {
                createItemWithId(([valuesFormateado].map(({ subcategoria, subCategId, id, ...rest }) => rest))[0], values.id)
                dispatch(newProduct(values))
                regresar()
            } catch (error) {
                alertaError(error)
            }
            // console.log(([valuesFormateado].map(({ subcategoria, subCategId, id, ...rest }) => rest))[0], values.id);
        } else{
            try {
                createItemWithId(([valuesFormateado].map(({ id, ...rest }) => rest))[0], values.id)
                dispatch(newProduct(values))
                regresar()
            } catch (error) {
                alertaError(error)
            }
            // console.log(([valuesFormateado].map(({ id, ...rest }) => rest))[0], values.id);
        }
    }

    const handleSubmitEdit = (values) => {
        try {
            const categ = values.categoria.split(" ~ ")
            values = {...values, categoria: categ[0], subcategoria: categ[1], subCategId: categ[2]}
            // console.log(values)
            editItem(([values].map(({ id, ...rest }) => rest))[0], values.id)
            dispatch(modProduct(values))
            props.handleEditClose()
        } catch (error) {
            alertaError(error)
            props.handleEditClose()
        }
    }

    if(!usuario || usuario.rol!=="admin"){
        return <Navigate to="/"/>
    }
    
    return(
        <>
            {!valoresEdicion ?
                <>
                    <Button 
                        variant="danger" 
                        className='ms-3 mt-3' 
                        style={{position: "absolute"}}
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <Container className='mt-5'>
                        <p className='fs-4'>Crear producto</p>
                    </Container>
                </>
                :  <></>
                }
            <Container>
                <Formik
                initialValues={valoresEdicion ? 
                        {
                            id: valoresEdicion.id,
                            codigo_barra: valoresEdicion.codigo_barra,
                            titulo: valoresEdicion.titulo,
                            descripcion: valoresEdicion.descripcion,
                            imagen: valoresEdicion.imagen,
                            precio: valoresEdicion.precio,
                            stock: valoresEdicion.stock,
                            oferta: valoresEdicion.oferta,
                            categoria: `${valoresEdicion.categoria} ~ ${valoresEdicion.subcategoria} ~ ${valoresEdicion.subCategId}`,
                            /* subcategoria: valoresEdicion.subcategoria,
                            subCategId: valoresEdicion.subCategId */
                        }
                    :
                    {
                        id: '',
                        codigo_barra: '',
                        titulo: '',
                        descripcion: '',
                        imagen: '',
                        precio: '',
                        stock: '',
                        oferta: false,
                        categoria: '',
                        /* subcategoria: '',
                        subCategId: '' */
                    }
                }
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={values => {
                    if(valoresEdicion){
                        handleSubmitEdit(values)
                    } else {
                        handleSubmitCreate(values)
                    }
                }}
                >
                    {({values, errors, touched }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="id">ID:</label>
                                <Field readOnly={valoresEdicion} name="id" className={(touched.id && errors.id) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                
                                {touched.id && errors.id && <div className='text-danger mb-2'>{errors.id}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="codigo_barra">Código de Barra:</label>
                                <Field name="codigo_barra" className={(touched.codigo_barra && errors.codigo_barra) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                
                                {touched.codigo_barra && errors.codigo_barra && <div className='text-danger mb-2'>{errors.codigo_barra}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="titulo">Título:</label>
                                <Field name="titulo" className={(touched.titulo && errors.titulo) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                
                                {touched.titulo && errors.titulo && <div className='text-danger mb-2'>{errors.titulo}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="descripcion">Descripción:</label>
                                <Field as="textarea" name="descripcion" className={(touched.descripcion && errors.descripcion) ? 'form-control is-invalid mb-3 descripcionProducto' : 'form-control mb-3 descripcionProducto'}/>
                                
                                {touched.descripcion && errors.descripcion && <div className='text-danger mb-2'>{errors.descripcion}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="imagen">Imagen (URL):</label>
                                <Field name="imagen" className={(touched.imagen && errors.imagen) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                
                                {touched.imagen && errors.imagen && <div className='text-danger mb-2'>{errors.imagen}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="precio">Precio: $ </label>
                                <Field name="precio" className={(touched.precio && errors.precio) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                
                                {touched.precio && errors.precio && <div className='text-danger mb-2'>{errors.precio}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="stock">Stock:</label>
                                <Field name="stock" className={(touched.stock && errors.stock) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                
                                {touched.stock && errors.stock && <div className='text-danger mb-2'>{errors.stock}</div>}
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="oferta">Oferta:</label>{' '}
                                {values.oferta ? "Si" : "No"}{' '}
                                <Field className="form-check-input" type="checkbox" name="oferta" />
                                {/* <Field name="oferta" className={(touched.oferta && errors.oferta) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/> */}
                                
                                {touched.oferta && errors.oferta && <div className='text-danger mb-2'>{errors.oferta}</div>}
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="categoria">Categoría:</label>
                                <Field 
                                    name="categoria" 
                                    as="select"
                                    className={(touched.categoria && errors.categoria) ? 'form-control is-invalid mb-3 fw-normal text-capitalize' : 'form-control mb-3 fw-normal text-capitalize'}
                                >
                                    <option value=""></option>
                                    {categorias.map((c, i) => (
                                        <option key={i} value={c.nombre}>{c.nombre}</option>
                                    ))}
                                </Field>
                                {touched.categoria && errors.categoria && <div className='text-danger mb-2'>{errors.categoria}</div>}
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="categoria">Categoría: {valoresEdicion ? `(Actual: "${values.categoria}")` : ""}</label>
                                <Field 
                                    name="categoria" 
                                    as="select"
                                    className={(touched.categoria && errors.categoria) ? 'form-control is-invalid mb-3 fw-normal text-capitalize' : 'form-control mb-3 fw-normal text-capitalize'}
                                >
                                    <option value=""></option>
                                    {categorias.map(c => (
                                        c.subfiltros.map(s => (
                                            s.lista.map(i => (
                                                <option 
                                                    key={`${c.nombre} ~ ${s.nombre} ~ ${i.nombre}`} 
                                                    value={`${c.nombre} ~ ${s.nombre} ~ ${i.nombre}`}
                                                    >
                                                    {`${c.nombre} ~ ${s.nombre} ~ ${i.nombre}`}
                                                </option>
                                            ))
                                        ))
                                    ))}
                                </Field>
                                
                                {touched.categoria && errors.categoria && <div className='text-danger mb-2'>{errors.categoria}</div>}
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="subcategoria">Subcategoría:</label>
                                <Field name="subcategoria" className={(touched.subcategoria && errors.subcategoria) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                {touched.subcategoria && errors.subcategoria && <div className='text-danger mb-2'>{errors.subcategoria}</div>}
                            </div> */}
                            {/* <div className="form-group">
                                <label htmlFor="subCategId">ID de Subcategoría:</label>
                                <Field name="subCategId" className={(touched.subCategId && errors.subCategId) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                                
                                {touched.subCategId && errors.subCategId && <div className='text-danger mb-2'>{errors.subCategId}</div>}
                            </div> */}
                            
                            <Button disabled={!valoresEdicion && listaIds.includes(values.id)} type="submit">{valoresEdicion ? "Editar" : "Crear"}</Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
};

export default ProductoForm