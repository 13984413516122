import React from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Button } from 'react-bootstrap';
import { registrar } from './funcionesAuth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useSelector } from 'react-redux';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    nombre: Yup.string()
        .required('Nombre requerido.'),
    apellido: Yup.string()
        .required('Apellido requerido.'),
    email: Yup.string()
        .email("Email inválido.")
        .required('Email requerido.'),
    contraseña: Yup.string()
        .min(6, "Debe tener como mínimo 6 caracteres.")
        .required("Contraseña requerida."),
    confirmacionContraseña: Yup.string()
        .oneOf([Yup.ref('contraseña'), null], 'Las contraseñas deben ser iguales'),
    rol: Yup.string()
        .required("Identificación requerida."),
    cuit: Yup.string()
        .min(10, "Debe tener por lo menos 10 caracteres")
        .required("CUIT requerido."),
});

const initialValues = {
    nombre: "",
    apellido: "",
    email: "",
    contraseña: "",
    confirmacionContraseña: "",
    rol: "",
    cuit: "",
}

const RegisterForm = ({ handleCloseRegister, auth }) => {
    const { listaRoles } = useSelector((state) => state.admin)

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={values => {
            registrar(createUserWithEmailAndPassword, auth, values.email, values.contraseña, values.rol, values.nombre, values.apellido, values.cuit)
        }}
        >
            {({ errors, touched }) => (
                <Form className="modal-content animate">
                    <div className="imgcontainer">
                        <img src={`${process.env.PUBLIC_URL}/img/mainlogo.png`} alt="Gecedata" className="avatar"/>
                    </div>
                    <br/>
                    <Container>
                        <h2>Registrarse</h2>
                        <h5>Complete los campos para realizar el registro</h5>
                        <hr/>
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre:</label>
                            <Field name="nombre" className={(touched.nombre && errors.nombre) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.nombre && errors.nombre && <div className='text-danger mb-2'>{errors.nombre}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="apellido">Apellido:</label>
                            <Field name="apellido" className={(touched.apellido && errors.apellido) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.apellido && errors.apellido && <div className='text-danger mb-2'>{errors.apellido}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <Field name="email" className={(touched.email && errors.email) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.email && errors.email && <div className='text-danger mb-2'>{errors.email}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="contraseña">Contraseña:</label>
                            <Field type="password" name="contraseña" className={(touched.contraseña && errors.contraseña) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.contraseña && errors.contraseña && <div className='text-danger mb-2'>{errors.contraseña}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmacionContraseña">Confirmar contraseña:</label>
                            <Field type="password" name="confirmacionContraseña" className={(touched.confirmacionContraseña && errors.confirmacionContraseña) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.confirmacionContraseña && errors.confirmacionContraseña && <div className='text-danger mb-2'>{errors.confirmacionContraseña}</div>}
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="rol"><b>Identificación:</b>(El registro no está habilitado para consumidores finales)</label>
                            <Field  type="password"
                                name="rol" 
                                as="select"
                                className={(touched.rol && errors.rol) ? 'form-control is-invalid mb-3 fw-normal text-capitalize' : 'form-control mb-3 fw-normal text-capitalize'}
                                >
                                    <option value=""></option>
                                {listaRoles.map(r => (
                                    <option key={r.id} value={r.id}>{r.nombre}</option>
                                    ))}
                            </Field>
                            {touched.rol && errors.rol && <div className='text-danger mb-2'>{errors.rol}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="cuit">CUIT:</label>
                            <Field name="cuit" className={(touched.cuit && errors.cuit) ? 'form-control is-invalid mb-3' : 'form-control mb-3'}/>
                            
                            {touched.cuit && errors.cuit && <div className='text-danger mb-2'>{errors.cuit}</div>}
                        </div>

                        <br/>
                        
                        <div className="botonesModal">
                            <Button variant="danger" onClick={handleCloseRegister}>Cerrar</Button>
                            <Button type="submit" variant="primary" onClick={handleCloseRegister}>Registrarse</Button>
                        </div>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default RegisterForm