import React from 'react'
import ProductoContainer from './producto/ProductoContainer'
import Carrusel from './Carrusel'

const Main = () => {
  return (
    <div>
        <Carrusel />
        <ProductoContainer />
    </div>
  )
}

export default Main
