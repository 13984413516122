import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import Sidemenu from "./Sidemenu"

const SideMenuResponsive = (props) => {
    return (
        <Offcanvas show={props.sideMenuResp} onHide={props.handleCloseMenu}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filtrar</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Sidemenu cerrarResponsive={props.handleCloseMenu}/>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SideMenuResponsive