import React from 'react'
import './DetalleCompra.css'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
const DetalleCompra = () => {

const { carrito, totalAPagar, descuentoAplicado } = useSelector((state) => state.products)


function formatoprecio(n) {
    return n.toLocaleString("de-DE");
}

    return(
        <div>
            <h5 className='mb-3 totalPago'>Total a Pagar: ${descuentoAplicado ? formatoprecio(totalAPagar*((100-descuentoAplicado)/100)) : formatoprecio(totalAPagar)}</h5>

            {carrito.map( (producto) => (
                <div key={producto.id} className="border-top">
                    <Row  className="mx-0 py-3 rowDetail" style={{width: "100%"}}>
                        <Col xs={12} sm={4} md={12} xl={4}>
                            <div className='d-flex justify-content-left align-items-start'>
                                {producto.imagen && producto.imagen!=="" ?
                                    <img className="imagen_cart" src={producto.imagen} alt={producto.descripcion}/>
                                :
                                    <img className="imagen_cart" src={`${process.env.PUBLIC_URL}/img/producto_sin_imagen.png`} alt={producto.descripcion}/>
                                }
                            </div>

                        </Col>
                        <Col xs={12} sm={4} md={12} xl={4}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <h2 className='fs-5 pt-2 fw-normal productoTitulo'>{producto.titulo}</h2>
                            </div> 
                        </Col>        
                        <Col xs={12} sm={1} md={12} xl={1}>
                                <h2 className='xCantidad'>X{producto.cantidad}</h2>                                                     
                        </Col>

                        <Col xs={12} sm={3} md={12} xl={3}>
                            <div className='pProducto'>
                                <p className="precioProducto">${descuentoAplicado ? formatoprecio((producto.precio*((100-descuentoAplicado)/100) * producto.cantidad)) : (formatoprecio(producto.precio * producto.cantidad))}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            ))}

        </div>
    )
}

export default DetalleCompra
