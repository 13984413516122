import React from 'react'
import './Carrusel.css';
import {Carousel} from 'react-bootstrap'

const Carrusel = () => {
    return (
        <div>
            <Carousel className='carrusel'>
                <Carousel.Item>
                    <img src={`${process.env.PUBLIC_URL}/img/BannerGecedata1.jpg`} alt="secondslide"/>
                </Carousel.Item>
                <Carousel.Item>  
                    <img src={`${process.env.PUBLIC_URL}/img/BannerGecedata2.jpg`} alt="secondslide"/>
                </Carousel.Item>
                <Carousel.Item>    
                    <img src={`${process.env.PUBLIC_URL}/img/BannerGecedata3.jpg`} alt="carruselfotouno" />             
                </Carousel.Item>                
            </Carousel>      
        </div>
    )
}

export default Carrusel
