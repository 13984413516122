import React, { useState, useEffect } from 'react'
import './Sidemenu.css';
import { useParams, Link } from 'react-router-dom';
import { Accordion, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Sidemenu = (props) => {

    const { categoria } = useParams()

    const { categorias } = useSelector((state) => state.products)

    const [filtros, setFiltros] = useState([]);

    useEffect(() => {
        if(categoria && categorias){
            if(categorias.length>0){
                const filtroActual = categorias.find(f => f.nombre === categoria)
                setFiltros(filtroActual)
            }
        }
    }, [categoria, categorias])

    return (
        <div className='mx-2'>
            <br />
            <h2 className='centrado_subrayado text-capitalize'>{categoria}</h2>
            <br />

            <Accordion>
                {(filtros && filtros.length!=0) ?

                    filtros.subfiltros.filter(i => i.visible).map((m,index) => 
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header>{m.nombre}</Accordion.Header>
                                <Accordion.Body className='px-0 py-0'>
                                    <ListGroup variant="flush" >
                                        {m.lista.map(sub => {
                                        if(sub.visible){
                                            return(
                                                <Link to={`/productos/${categoria}/${m.nombre.toLowerCase()}/${sub.nombre.toLowerCase()}`} key={sub.nombre}>
                                                    <ListGroup.Item className='subCategoria' onClick={() => props.cerrarResponsive && props.cerrarResponsive()} action>
                                                        {sub.nombre}
                                                    </ListGroup.Item>
                                                </Link>
                                            )
                                        } else {return(<></>)}
                                        }
                                        )}
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                : <></>
                }
            </Accordion>
        </div>
    )
}

export default Sidemenu
