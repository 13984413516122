import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCategorias, getAllItems, getAllItemsAdmin } from '../../firebase/firebaseFunctions'

const initialState = {
    loading: false,
    listaProductos: [],
    listaFiltrada: [],
    palabraBuscada: "",
    categorias: [],
    descuentoAplicado: 0,
    carrito: [],
    carritoCant: 0,
    totalAPagar: 0,
    ordenCompra: [],
    error: '',
    cant: 5,
}

// Generates pending, fulfilled and rejected action types
export const fetchProducts = createAsyncThunk('products/fetchProductos', (rol) => {
    if(rol==="admin"){
        return(getAllItemsAdmin())
    } else{
        return(getAllItems())
    }
})

export const fetchCategorias = createAsyncThunk('products/fetchCategorias', () => {
    return(getAllCategorias())
})

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setDescuentoAplicado: (state, action) => {
            state.descuentoAplicado = action.payload
        },
        setCategorias: (state, action) => {
            state.categorias = action.payload
        },
        addToCart: (state, action) => {
            // console.log(action.payload)
            const isInCart = state.carrito.find(f => f.id===action.payload.id)===undefined ? false : true
            if(isInCart){
                state.carrito = state.carrito.map(c => c.id===action.payload.id ? {...c, cantidad: c.cantidad+action.payload.cantidad} : c)
            } else{
                state.carrito.push(action.payload);
            }
            // console.log(action.payload)
            state.totalAPagar += action.payload.precio * action.payload.cantidad
            state.carritoCant += action.payload.cantidad
        },
        clearCart: (state, action) => {
            state.carrito = []
            state.carritoCant = 0
            state.totalAPagar = 0
        },
        removeFromCart: (state, action) => {
            state.carrito = state.carrito.filter(f => f.id!==action.payload.id);
            state.carritoCant -= action.payload.cantidad
            state.totalAPagar -= action.payload.precio * action.payload.cantidad
        },
        setOrdenCompra: (state, action) => {
            state.ordenCompra = action.payload
        },
        newProduct: (state, action) => {
            state.listaProductos = [...state.listaProductos, action.payload]
        },
        modProduct: (state, action) => {
            state.listaProductos = state.listaProductos.map(i => i.id===action.payload.id ? (action.payload) : i)
        },
        deletedProduct: (state, action) => {
            state.listaProductos = state.listaProductos.filter(i => i.id!==action.payload)
        },
        filtrarXOferta: (state, action) => {
            state.listaFiltrada = state.listaProductos.filter(f => f.oferta==true)
        },
        filtrarListaXCateg: (state, action) => {
            state.listaFiltrada = state.listaProductos.filter(f => f.categoria==action.payload)
        },
        filtrarListaXSubcateg: (state, action) => {
            state.listaFiltrada = state.listaProductos.filter(f => f.subcategoria==action.payload.subcategoria && f.subCategId==action.payload.id.trim())
        },
        filtrarListaXPalabra: (state, action) => {
            state.listaFiltrada = state.listaProductos.filter(f => f.titulo.toLowerCase().includes(state.palabraBuscada.toLowerCase()) || f.descripcion.toLowerCase().includes(state.palabraBuscada.toLowerCase()))
        },
        setPalabraBuscada: (state, action) => {
            state.palabraBuscada = action.payload
        },
        modStocks: (state, action) => {
            state.listaProductos = state.listaProductos.map(p => p.id==action.payload.id ? {...p, stock: action.payload.stock} : p)
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchProducts.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.loading = false
            state.listaProductos = action.payload
            state.error = ''
        })
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.loading = false
            state.listaProductos = []
            state.error = action.error.message
        })
        builder.addCase(fetchCategorias.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchCategorias.fulfilled, (state, action) => {
            state.loading = false
            state.categorias = action.payload
            state.error = ''
        })
        builder.addCase(fetchCategorias.rejected, (state, action) => {
            state.loading = false
            state.categorias = []
            state.error = action.error.message
        })
    }
})

export default productsSlice.reducer
export const { 
    addToCart,
    removeFromCart,
    newProduct, 
    modProduct, 
    deletedProduct,
    clearCart,
    setOrdenCompra,
    filtrarXOferta,
    filtrarListaXCateg,
    filtrarListaXSubcateg,
    filtrarListaXPalabra,
    setPalabraBuscada,
    setDescuentoAplicado,
    setCategorias,
    modStocks,
} = productsSlice.actions