import React from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Button } from 'react-bootstrap';
import { editItemStock, editItemStockAdmin, editSaleStatus, getSingleItem } from '../../firebase/firebaseFunctions'
import { modSaleStatus } from '../../redux/slices/adminSlice';
import { useDispatch } from 'react-redux';
import { modStocks } from '../../redux/slices/productsSlice';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    status: Yup.string()
        .required('Requerido'),
});

const VentaForm = (props) => {
    const dispatch = useDispatch()

    const initialValue = props.modalEditContent && props.modalEditContent.status

    const handleSubmitEdit = (sale, status) => {
        try {
            editSaleStatus(([{...sale, status: status.status}].map(({ id, ...rest }) => rest))[0], sale.id)
            dispatch(modSaleStatus({...sale, status: status.status}))
            if((initialValue==="pendiente" || initialValue==="pago") && status.status==="cancelado"){
                let prodData = null
                sale.productos.map(async p => {
                    prodData = await getSingleItem(p.id)
                    editItemStockAdmin({...prodData, cantidad: p.cantidad}, p.id)
                    dispatch(modStocks({id: p.id, stock: Number(prodData.stock)+Number(p.cantidad)}))
                })
            } else if(initialValue==="cancelado"){
                let prodData = null
                sale.productos.map(async p => {
                    prodData = await getSingleItem(p.id)
                    editItemStock({...prodData, cantidad: p.cantidad}, p.id)
                    dispatch(modStocks({id: p.id, stock: Number(prodData.stock)-Number(p.cantidad)}))
                })
            }
            props.handleEditClose()
        } catch (error) {
            props.handleEditClose()
        }
    }

    return (
        <Container>
            <Formik
            initialValues={{
                    status: props.modalEditContent && props.modalEditContent.status,
                }
            }
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={values => {
                handleSubmitEdit(props.modalEditContent, values)
            }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="status">Estado de la compra:</label>
                            <Field 
                                name="status" 
                                as="select"
                                className={(touched.status && errors.status) ? 'form-control is-invalid mb-3 fw-normal text-capitalize' : 'form-control mb-3 fw-normal text-capitalize'}
                            >
                                {["pendiente", "pago", "cancelado"].map(s => (
                                    <option className='text-capitalize' key={s} value={s} defaultValue={s===props.modalEditContent.status}>{s}</option>
                                ))}
                            </Field>
                            {touched.status && errors.status && <div className='text-danger mb-2'>{errors.status}</div>}
                        </div>
                        
                        <Button type="submit">Editar</Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default VentaForm